<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.go(-1)"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">Personal Acompañante</h3>
                    <form @submit.prevent="updatePersonal">
                        <div class="form-group">
                            <label>Cita</label>
                            <select
                                class="form-control"
                                v-model="personal.cita"
                            >
                                <option
                                    v-for="cita in citas"
                                    :key="cita.id"
                                    :value="cita.id"
                                    v-text="cita.especialista"
                                >
                                    {{ cita.especialista }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Fecha</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="personal.fecha"
                            />
                        </div>
                        <div class="form-group">
                            <label>Acompañante</label>
                            <select
                                class="form-control"
                                v-model="personal.personal"
                            >
                                <option
                                    v-for="usuario in usuarios"
                                    :key="usuario.id"
                                    :value="usuario.id"
                                    v-text="usuario.name"
                                >
                                    {{ usuario.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Detalle</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personal.detalle"
                            />
                        </div>
                        <div class="form-group">
                            <label>Estado</label>
                            <div class="control">
                                <input
                                    type="radio"
                                    id="one"
                                    value="Por asistir"
                                    v-model="personal.estado"
                                />
                                <label for="one" style="margin-right: 3%;">Por asistir</label>

                                <input
                                    type="radio"
                                    id="two"
                                    value="Asistida"
                                    v-model="personal.estado"
                                />
                                <label for="two" style="margin-right: 3%;">Asistida</label>
                                <input
                                    type="radio"
                                    id="two"
                                    value="Reprogramar"
                                    v-model="personal.estado"
                                />
                                <label for="two" style="margin-right: 3%;">Reprogramar</label>
                            </div>
                        </div>
                        <button type="submit" class="btnAgregar">Editar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            personal: {},
            citas: [],
            usuarios: [],
        };
    },
    created() {
        this.axios
            .get(`/api/personales/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.personal = response.data.personal;
                this.citas = response.data.citas;
                this.usuarios = response.data.usuarios;
            });
    },
    methods: {
        updatePersonal() {
            this.axios
                .patch(
                    `/api/personales/${this.$route.params.id}`,
                    this.personal, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((res) => {
                    this.$router.go(0);
                });
            Swal.fire({
                icon: "success",
                title: "Actualizado!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>
