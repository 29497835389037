var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-1 col-md-12"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-8 col-xs-12 text-right",staticStyle:{"margin-top":"0.5%"}},[_c('div',{staticClass:"btn_group"},[_c('router-link',{staticClass:"btn btn-info pull-left",staticStyle:{"margin-top":"-7px","margin-left":"2px"},attrs:{"to":"/addRecibosColaboradores","id":"agregar"}},[_vm._v("Generar recibo de pago "),_c('i',{staticClass:"fas fa-plus"})])],1)])])]),_c('div',{staticClass:"panel-body table-responsive"},[(_vm.listLength == 0)?_c('NoExist'):_vm._e(),(_vm.listLength != 0)?_c('table',{staticClass:"table table-striped",attrs:{"id":"colaboradores"}},[_vm._m(1),_c('tbody',_vm._l((_vm.datos),function(recibo){return _c('tr',{key:recibo.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.nombre))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.periodo))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.horasextra))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.feriados))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.vacaciones))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.total_deducciones))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.total_bruto))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(recibo.total_pagar))]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteRecibo(
                                            recibo.id
                                        )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]),_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.confirmarDescarga(
                                            recibo.id
                                        )}}},[_c('i',{staticClass:"fas fa-file-download"})])])])])}),0)]):_vm._e(),(_vm.listLength != 0)?_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getPreviousPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(" < ")])]),_vm._l((_vm.totalPaginas()),function(pagina){return _c('li',{key:_vm.isActive(pagina),staticClass:"page-item",on:{"click":function($event){return _vm.getDataPagina(pagina)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(pagina))])])}),_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getNextPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(">")])])],2)]):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col col-sm-4 col-xs-6"},[_c('h4',{staticClass:"title"},[_vm._v("Historial de recibos de pago")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Colaborador")]),_c('th',{staticClass:"text-center"},[_vm._v("Periodo")]),_c('th',{staticClass:"text-center"},[_vm._v("Horas extras")]),_c('th',{staticClass:"text-center"},[_vm._v("Feriados")]),_c('th',{staticClass:"text-center"},[_vm._v("Vacaciones")]),_c('th',{staticClass:"text-center"},[_vm._v("Deducciones")]),_c('th',{staticClass:"text-center"},[_vm._v("Salario bruto")]),_c('th',{staticClass:"text-center"},[_vm._v("Total a pagar")]),_c('th',{staticClass:"text-center"},[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }