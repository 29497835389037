require('./bootstrap')
window.Vue = require('vue')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://api.alberguemarianojuvenil.org';



Vue.component('file-uploader-component', require('./components/FileUploaderComponent.vue').default);

// captura las respuestas con codigo 401 para controlar si esta autorizado o no
axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status == 401) {
            const currentRoute = router.currentRoute;
            //excluye la ruta del login
            if (currentRoute.name !== 'Login') {
                await Swal.fire({
                    title: 'Sesión Expirada',
                    text: 'Tu sesión ha expirado. Por favor, vuelve a iniciar sesión.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });

                // Limpiar el token y el usuario del store
                store.commit('clearToken');
                store.commit('clearUser');

                // Redirigir al login
                router.push('/login');

                return new Promise(() => { });
            }
        } else {
            return Promise.reject(error);
        }

    }
);

Vue.use(VueAxios, axios);

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

const getSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return subdomain;
};

const subdomain = getSubdomain();
//console.log(subdomain);

axios.defaults.headers.common['X-Subdomain'] = subdomain;

axios.get('/api/config',{params:{subdomain: subdomain}})
  .then(response => {
    const config = response.data;
    store.commit('setLogo', config.logoPath);
    //console.log(response.data);
    // Aplicar la configuración
    document.documentElement.style.setProperty('--color-primario', config.primary_color);
    document.documentElement.style.setProperty('--color-secundario', config.secondary_color);

    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  })
  .catch(error => {
    console.error('Error fetching config:', error);
  });


