<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px; max-width: 5%">
                            <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                                    class="fas fa-arrow-alt-circle-left"></i></a>
                        </div>
                    </div>
                    <div class="row text-center" style="margin-top: 2%; margin-left: 20px;">
                        <div class="col" v-if="listLength != 0">
                            <label>Fecha Ingreso</label>
                            <input type="date" id="fecha_Ingreso"
                                style="width: 200px; border-radius: 5px; height: 40px;" />
                        </div>
                        <div class="col" v-if="listLength != 0">
                            <label>Fecha Egreso</label>
                            <input type="date" id="fecha_Egreso"
                                style="width: 200px; border-radius: 5px; height: 40px;" />
                        </div>
                        <div class="col" style="margin-top: 2.5%">
                            <button id="agregar1" class="btn btn-info" @click="buscar()" style="width: 200px">
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col" style="margin-top: 2.5%">
                            <button id="agregar1" style="
                                    background-color: #fec107;
                                    border-color: #fec107;
                                    width: 200px;
                                " class="btn btn-danger" @click="generateReport()">
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table class="
                            table table-bordered table-striped table-condensed
                        " v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Foto</th>
                                <th>Identificación</th>
                                <th>Encargado</th>
                                <th>Oficina Procedencia</th>
                                <th>Fecha Ingreso</th>
                                <th>Fecha Egreso</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td>{{ reporteL.nombre }}</td>
                                <td>
                                    <img class="imagenF" width="100" height="100"
                                        :src="`${filebaseurl}/jovenes/${reporteL.foto}`" alt="" />
                                </td>
                                <td>{{ reporteL.identificacion }}</td>
                                <td>{{ reporteL.encargado }}</td>
                                <td>{{ cargarOficina(reporteL.oficina) }}</td>
                                <td>{{ reporteL.fecha_Ingreso }}</td>
                                <td>{{ reporteL.fecha_Egreso }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
                        :paginate-elements-by-height="1400" filename="IngresosEgresosJovenes" :pdf-quality="2"
                        :manual-pagination="false" pdf-format="a4" pdf-orientation="landscape"
                        pdf-content-width="1000px" ref="html2Pdf">
                        <section slot="pdf-content" style="
                                color: black;
                                padding-left: 13%;
                                padding-top: 4%;
                            " class="
                                align-items-center
                                justify-content-center
                                text-center
                            ">
                            <h2 class="text-center" style="margin-left: 6%; margin-bottom: 5%">
                                Fechas de ingreso y egresos PMEs
                            </h2>
                            <div class="panel-body table-responsive">
                                <NoExist v-if="listLength == 0" />
                                <table class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    " v-if="listLength != 0">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Foto</th>
                                            <th>Identificación</th>
                                            <th>Encargado</th>
                                            <th>Oficina Procedencia</th>
                                            <th>Fecha Ingreso</th>
                                            <th>Fecha Egreso</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="reporteL in datos" :key="reporteL.id">
                                            <td>{{ reporteL.nombre }}</td>
                                            <td>
                                                <img class="imagenF" width="100" height="100"
                                                    :src="`${filebaseurl}/jovenes/${reporteL.foto}`" alt="" />
                                            </td>
                                            <td>
                                                {{ reporteL.identificacion }}
                                            </td>
                                            <td>{{ reporteL.encargado }}</td>
                                            <td>
                                                {{
                                                    cargarOficina(
                                                        reporteL.oficina
                                                    )
                                                }}
                                            </td>
                                            <td>
                                                {{ reporteL.fecha_Ingreso }}
                                            </td>
                                            <td>{{ reporteL.fecha_Egreso }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            filebaseurl: process.env.VUE_APP_FILES_BASE_URL,
            jovenes: [],
            datos: {},
            oficinas: [],
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/fechas`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.jovenes = response.data.jovenes;
            this.oficinas = response.data.oficinas;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            var fecha_Ingreso = document.getElementById("fecha_Ingreso").value;
            var fecha_Egreso = document.getElementById("fecha_Egreso").value;
            var f = new Date();
            var fecha =
                f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();
            var aux = new Array();
            for (var key in this.jovenes) {
                if (fecha_Egreso === "") {
                    if (this.jovenes[key].fecha_Ingreso <= fecha) {
                        var aux2 = {
                            nombre: "",
                            foto: "",
                            identificacion: "",
                            encargado: "",
                            oficina: "",
                            fecha_Ingreso: "",
                            fecha_Egreso: "",
                        };
                        aux2.nombre = this.jovenes[key].nombre;
                        aux2.foto = this.jovenes[key].foto;
                        aux2.identificacion = this.jovenes[key].identificacion;
                        aux2.encargado = this.jovenes[key].persona_Encargada;
                        aux2.oficina = this.jovenes[key].oficina_Procedencia;
                        aux2.fecha_Ingreso = this.jovenes[key].fecha_Ingreso;
                        aux2.fecha_Egreso = this.jovenes[key].fecha_Egreso;
                        aux.push(aux2);
                    } else {
                        Swal.fire({
                            title: "No hay datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                } else if (fecha_Ingreso === "") {
                    if (fecha <= this.jovenes[key].fecha_Egreso) {
                        var aux2 = {
                            nombre: "",
                            foto: "",
                            identificacion: "",
                            encargado: "",
                            oficina: "",
                            fecha_Ingreso: "",
                            fecha_Egreso: "",
                        };
                        aux2.nombre = this.jovenes[key].nombre;
                        aux2.foto = this.jovenes[key].foto;
                        aux2.identificacion = this.jovenes[key].identificacion;
                        aux2.encargado = this.jovenes[key].persona_Encargada;
                        aux2.oficina = this.jovenes[key].oficina_Procedencia;
                        aux2.fecha_Egreso = this.jovenes[key].fecha_Egreso;
                        aux2.fecha_Ingreso = this.jovenes[key].fecha_Ingreso;
                        aux.push(aux2);
                    } else {
                        Swal.fire({
                            title: "No hay datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                } else if (
                    this.jovenes[key].fecha_Ingreso >= fecha_Ingreso &&
                    this.jovenes[key].fecha_Egreso <= fecha_Egreso
                ) {
                    var aux2 = {
                        nombre: "",
                        foto: "",
                        identificacion: "",
                        encargado: "",
                        oficina: "",
                        fecha_Ingreso: "",
                        fecha_Egreso: "",
                    };
                    aux2.nombre = this.jovenes[key].nombre;
                    aux2.foto = this.jovenes[key].foto;
                    aux2.identificacion = this.jovenes[key].identificacion;
                    aux2.encargado = this.jovenes[key].persona_Encargada;
                    aux2.oficina = this.jovenes[key].oficina_Procedencia;
                    aux2.fecha_Ingreso = this.jovenes[key].fecha_Ingreso;
                    aux2.fecha_Egreso = this.jovenes[key].fecha_Egreso;
                    aux.push(aux2);
                }
            }
            this.datos = aux;
        },
        cargarOficina(oficinaId) {

            const id = Number(oficinaId);
            const oficina = this.oficinas.find(oficina => oficina.id === id);
            if (oficina) {
                return oficina.nombre;
            } else {
                return 'Oficina no encontrada';
            }
        },
    },
};
</script>
