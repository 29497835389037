var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-1 col-md-12"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-sm-3 col-xs-10",staticStyle:{"padding-right":"0px"}},[_c('a',{staticClass:"btn btn-info pull-right",attrs:{"id":"volver"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"})]),_c('h3',{staticClass:"title"},[_vm._v("Datos de llamadas")])]),_c('div',{staticClass:"col-sm-9 col-xs-12 text-right"},[_c('div',{staticClass:"btn_group"},[_c('router-link',{staticClass:"btn btn-info pull-left",staticStyle:{"margin-top":"-7px","margin-left":"2px"},attrs:{"to":{
                                name: 'addLlamadas',
                                params: { id: _vm.joven.id },
                            },"id":"agregar"}},[_vm._v("Agregar "),_c('i',{staticClass:"fas fa-plus"})])],1)])])]),_c('div',{staticClass:"panel-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-condensed"},[_vm._m(0),_c('tbody',_vm._l((_vm.datos),function(llamada){return _c('tr',{key:llamada.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(llamada.nombre))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(llamada.numero_telefono))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(llamada.tiempo))]),(
                                llamada.autorizado_por ===
                                'Administrador'
                            )?_c('div',{staticClass:"justify-content-center align-items-center"},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(llamada.autorizado_por))])]):_c('div',{staticClass:"justify-content-center align-items-center"},[_c('td',{staticClass:"text-center"},[_vm._v(" Oficina de "+_vm._s(llamada.autorizado_por)+" ")])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
                                        name: 'editLlamadas',
                                        params: { id: llamada.id },
                                    }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteLlamada(llamada.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)])])}),0)]),_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getPreviousPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}})]),_vm._l((_vm.totalPaginas()),function(pagina){return _c('li',{key:_vm.isActive(pagina),staticClass:"page-item",on:{"click":function($event){return _vm.getDataPagina(pagina)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(pagina))])])}),_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getNextPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(">")])])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Número de teléfono")]),_c('th',{staticClass:"text-center"},[_vm._v("Tiempo de llamada")]),_c('th',{staticClass:"text-center"},[_vm._v("Autorizado por")]),_c('th',{staticClass:"text-center"})])])
}]

export { render, staticRenderFns }