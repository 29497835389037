<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div
                            class="col col-sm-3 col-xs-10"
                            style="padding-right: 0px; max-width: 5%"
                        >
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div class="col text-right">
                            <div class="col">
                                <button
                                    id="agregar1"
                                    style="
                                        background-color: #fec107;
                                        border-color: #fec107;
                                        width: 150px;
                                    "
                                    class="btn btn-danger"
                                    @click="generateReport()"
                                >
                                    Descargar PDF
                                    <i class="fa-solid fa-file-pdf"></i>
                                </button>
                            </div>
                        </div>
                        <div
                            class="col-sm-9 col-xs-12 text-center"
                            style="margin-left: 9%"
                            v-if="listLength != 0"
                        >
                            <label id="reportes">Periodo</label>
                            <select id="periodo">
                                <option
                                    id="datos"
                                    v-for="periodo in colillasLimpias"
                                    :key="periodo.periodo"
                                    :value="periodo.periodo"
                                    v-text="periodo.periodo"
                                ></option>
                            </select>
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                @click="buscar()"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th>Colaborador</th>
                                <th>Puesto</th>
                                <th>Fecha</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td>{{ reporteL.colaborador }}</td>
                                <td>{{ reporteL.puesto }}</td>
                                <td>{{ reporteL.fecha }}</td>
                                <td>₡{{ reporteL.monto }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Planillaporperiodo"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="800px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style="
                                color: black;
                                /* padding-left: 21%; */
                                width: 100px;
                                margin: 0 auto;
                                padding-top: 2%;
                            "
                            class="
                                align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2 class="text-center" style="margin-left: 23%">
                                Control de planilla por periodo
                            </h2>
                            <div class="panel-body table-responsive">
                                <NoExist v-if="listLength == 0" />
                                <table
                                    class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    "
                                    v-if="listLength != 0"
                                >
                                    <thead>
                                        <tr>
                                            <th>Colaborador</th>
                                            <th>Puesto</th>
                                            <th>Fecha</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td>{{ reporteL.colaborador }}</td>
                                            <td>{{ reporteL.puesto }}</td>
                                            <td>{{ reporteL.fecha }}</td>
                                            <td>₡{{ reporteL.monto }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            colillas: [],
            colaboradores: [],
            periodo: {},
            datos: {},
            colillasLimpias: [],
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/planilla`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.colillas = response.data.colillas;
            this.colaboradores = response.data.colaboradores;
            this.listLength = this.colillas.length;
            this.limpiarColillas(this.colillas);
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            var periodo = document.getElementById("periodo").value;
            var aux = new Array();
            for (var key in this.colillas) {
                if (this.colillas[key].periodo == periodo) {
                    var aux2 = {
                        colaborador: "",
                        puesto: "",
                        monto: "",
                        fecha: "",
                    };
                    for (var key1 in this.colaboradores) {
                        if (
                            this.colillas[key].colaborador ==
                            this.colaboradores[key1].id
                        ) {
                            aux2.colaborador = this.colaboradores[key1].nombre;
                            aux2.puesto = this.colaboradores[key1].puesto;
                            var fecha = new Date(this.colillas[key].created_at);
                            aux2.fecha =
                                fecha.getUTCFullYear() +
                                "-" +
                                fecha.getUTCMonth() +
                                "-" +
                                fecha.getUTCDate();
                            aux2.monto = this.colillas[key].neto;
                            aux.push(aux2);
                        }
                    }
                }
            }
            this.datos = aux;
        },
        limpiarColillas(colillas) {
            var limpias = _.uniqBy(colillas, "periodo");
            this.colillasLimpias = limpias;
        },
    },
};
</script>
