<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel" id="grande">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Jóvenes</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link to="/addjoven"  id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Joven <i class="fas fa-plus"></i></router-link>
                            </div>
                            </div>
                          <input v-if="listLength != 0" type="text" v-model="busqueda" id='busqueda' class="form-control" placeholder="Buscar" @keyup.enter="searchJoven()" />
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table class="table table-bordered table-striped table-condensed" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Nombre</th>
                            <th>Fecha Nacimiento</th>
                            <th>Identificación</th>
                            <th>Foto</th>
                            <th>Fecha Ingreso</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="joven in this.jovenes" :key="joven.id">
                        <td>{{ joven.nombre }}</td>
                        <td>{{ joven.fecha_Nacimiento }}</td>
                        <td>{{ joven.identificacion }}</td>
                        <td><img  class="imagenF" width="100" height="100" :src="`${filebaseurl}/jovenes/${joven.foto}`" alt=""></td>
                        <td>{{ joven.fecha_Ingreso}}</td>
                        <td>
                        <div class="btn-group" role="group">
                            <router-link
                            :to="{ name: 'editjoven', params: { id: joven.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deleteJoven(joven.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                            <router-link
                            :to="{ name: 'menuJovenes', params: { id: joven.id } }"
                            class="btn btn-danger"
                            id="botonJovenes"
                            ><i class="fas fa-plus"></i></router-link>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      filebaseurl:process.env.VUE_APP_FILES_BASE_URL,
      jovenes:[],
      pageSize:10,
      datos:[],
      paginaActual:1,
      oficinas:[],
      busqueda:'',
           listLength:0
    };
  },
  created() {
    // loading events for specific joven
    this.axios.get("/api/jovenes?status=active", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      console.log(process.env.VUE_APP_FILES_BASE_URL);
      this.jovenes = response.data.jovenes;
      this.oficinas = response.data.oficinas
      this.listLength = this.jovenes.length;
    }).catch(error => {
      console.log(error);
    });
  },
  computed:{

  },
   methods: {
    searchJoven() {
      this.axios.get(`/api/jovenes/search?term=${this.busqueda.toLowerCase()}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
        this.jovenes = response.data.jovenes;
        this.listLength = this.jovenes.length;
      }).catch(error => {
        console.log(error);
      });
    },
    deleteJoven(id) {
      this.axios
        .delete(`/api/jovenes/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.jovenes.map((data) => data.id).indexOf(id);
          this.jovenes.splice(i, 1);
          this.reload();
        });
        Swal.fire({
            title: 'Eliminado!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
        })
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    },
    cargarOficina(id){
       var nombres = '';
       for(var key in this.oficinas){
         for(var i in this.oficinas[key]){
          if(this.oficinas[key].id == id){

            nombres = this.oficinas[key].nombre;
          }
         }
       }
       return nombres;
    },
  },
};
</script>