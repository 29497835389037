
<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Evento de Colaborador</h3>
          <form @submit.prevent="addEventoColaborador">
            <div class="form-group1">
              <label>Tipo de Evento</label>
              <select class="form-control" v-model="evento.tipo">
                <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id" v-text="tipo.nombre">
                  {{tipo.nombre}}
                </option>
              </select>
            </div>
            <div class="form-group1" v-if="evento.tipo !== 11">
              <label>Detalle</label>
              <input type="text" class="form-control" v-model="evento.detalle" />
            </div>
            <div v-if="evento.tipo === 11">
              <div class="row" style="margin-bottom: 4%; margin-top: 3%">
                <div class="col">
                  <label>Desde el</label>
                  <input type="date" class="form-control" v-model="vacacion.desde" />
                </div>
                <div class="col">
                  <label>Hasta el</label>
                  <input type="date" class="form-control" v-model="vacacion.hasta" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>Detalle</label>
                  <textarea type="text" class="form-control" v-model="vacacion.comentarios" rows="3"></textarea>
                </div>
              </div>
            </div>
            <button type="submit" class="btnAgregar">Agregar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipos: [],
      tipo: {},
      colaborador: {},
      evento: { 'colaborador': '' },
      vacacion: {},
    };
  },
  created() {
    this.axios
      .get(`/api/eventoscolaboradores/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.colaborador = response.data.colaborador;
        this.tipos = response.data.tipos;
        this.tipos = this.editarTipos(this.tipos);
      });
  },
  methods: {
    editarTipos(tipos) {
      var variable = "Colaboradores";
      var tipos1 = [];
      for (var key in tipos) {
        if (tipos[key].categoria == variable) {
          tipos1.push(tipos[key]);
        }
      }
      return tipos1;
    },
    addEventoColaborador() {
      let formDataEvento = new FormData();
      formDataEvento.append('colaborador', this.colaborador.id);
      formDataEvento.append('tipo', this.evento.tipo);
      if (this.evento.tipo !== 11) {
        formDataEvento.append('detalle', this.evento.detalle);
      } else {
        formDataEvento.append('detalle', this.vacacion.comentarios);
      }
      this.axios
        .post("/api/eventoscolaboradores", formDataEvento, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) =>{} )
        // .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      Swal.fire({
        icon: 'success',
        title: ' Evento de Colaborador Creado!',
        showConfirmButton: false,
        timer: 1500
      })
      if (this.evento.tipo === 11) {
        let formDataVacas = new FormData();
        formDataVacas.append('usuario', this.colaborador.id);
        formDataVacas.append('nombre', this.colaborador.nombre);
        formDataVacas.append('desde', this.vacacion.desde);
        formDataVacas.append('hasta', this.vacacion.hasta);
        formDataVacas.append('comentarios', this.vacacion.comentarios);

        this.axios
          .post("/api/vacacionesempleados", formDataVacas, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
          .then((response) => {
            var resultado = response.data.resultado["resultado"];
            var x = parseInt(resultado[0]) === parseInt(1);
            var y = parseInt(resultado[0]) === parseInt(3);
            var z = parseInt(resultado[0]) === parseInt(4);
            var w = parseInt(resultado[0]) === parseInt(5);
            var v = parseInt(resultado[0]) === parseInt(6);
            if (x) {
              Swal.fire({
                title: "Ingrese todos los datos",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (y) {
              Swal.fire({
                title: "La fecha de salida a vacaciones no puede ser menor o igual a la fecha actual",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
              });
            } else if (z) {
              Swal.fire({
                title: "Debe seleccionar correctamente las fechas",
                icon: "error",
                showConfirmButton: false,
                timer: 2500,
              });
            } else if (w) {
              Swal.fire({
                title: "Cantidad de días no disponibles",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (v) {
              Swal.fire({
                title: "Ya tienes días de vacaciones en estás fechas, intenta con otras fechas",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Vacaciones solicitadas!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.reload();
            }
          });
      }

    },

  },
};
</script>