<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/permisosempleados" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Solicitud de permiso</h3>
                    <form @submit.prevent="addPermisosEmpleados" class="
                            text-center
                            justify-content-center
                            align-items-center
                        ">
                        <div class="row" style="margin-bottom: 3%;">
                            <div class="col">
                                <label>Desde el</label>
                                <input type="date" class="form-control" v-model="permiso.desde" />
                            </div>
                            <div class="col">
                                <label>Hasta el</label>
                                <input type="date" class="form-control" v-model="permiso.hasta" />
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 3%;">
                            <div class="col">
                                <label>Hora de inicio</label>
                                <input type="time" class="form-control" v-model="permiso.hora_inicio" />
                            </div>
                            <div class="col">
                                <label>Hora fin</label>
                                <input type="time" class="form-control" v-model="permiso.hora_fin" />
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 3%;">
                            <div class="col">
                                <label>Motivo</label>
                                <input type="text" class="form-control" v-model="permiso.motivo" required/>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 3%;">
                            <div class="col">
                                <label>Comentarios</label>
                                <textarea type="text" class="form-control" v-model="permiso.comentarios" rows="5"
                                    cols="50"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btnAgregar">
                                    Solicitar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            permisos: [],
            permiso: {},
        };
    },
    created() {
        this.axios.get("/api/permisosempleados", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.permisos = response.data.permisos;
        });
    },
    methods: {
        addPermisosEmpleados() {
            let formData = new FormData();
            formData.append("desde", this.permiso.desde);
            formData.append("hasta", this.permiso.hasta);
            formData.append("motivo", this.permiso.motivo);
            formData.append("hora_inicio", this.permiso.hora_inicio);
            formData.append("hora_fin", this.permiso.hora_fin);
            formData.append("comentarios", this.permiso.comentarios);
            this.axios
                .post("/api/permisosempleados", formData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    var y = parseInt(resultado[0]) === parseInt(3);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                    if (y) {
                        Swal.fire({
                            title: "La fecha del permiso no puede ser menor o igual a la fecha actual",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 2500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Permiso solicitado!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
        },
    },
};
</script>
