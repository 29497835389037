<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col">
                            <h4 class="title">Mi historial de permisos</h4>
                        </div>
                        <div class="col text-right">
                            <div class="btn_group pull-left">
                                <router-link
                                    to="/addPermisosEmpleados"
                                    id="agregar"
                                    class="btn btn-info"
                                    style="margin-top: -7px; margin-left: 2px"
                                    >Solicitar permiso
                                    <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Desde</th>
                                <th class="text-center">Hasta</th>
                                <th class="text-center">Hora de inicio</th>
                                <th class="text-center">Hora fin</th>
                                <th class="text-center">Motivo</th>
                                <th class="text-center">Estado</th>
                                <th class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="permiso in searchPermiso"
                                :key="permiso.id"
                            >
                                <td class="text-center">{{ permiso.desde }}</td>
                                <td class="text-center">{{ permiso.hasta }}</td>
                                <td class="text-center">
                                    {{ permiso.hora_inicio }}
                                </td>
                                <td class="text-center">
                                    {{ permiso.hora_fin }}
                                </td>
                                <td class="text-center">
                                    {{ permiso.comentarios }}
                                </td>
                                <td class="text-center">
                                    {{ permiso.estado }}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <button
                                            class="btn btn-danger"
                                            @click="
                                                deletePermisos(permiso.usuario)
                                            "
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav
                        aria-label="Page navigation example"
                        v-if="listLength != 0"
                    >
                        <ul class="pagination justify-content-center">
                            <li
                                class="page-item"
                                v-on:click="getPreviousPage()"
                            >
                                <a class="page-link" href="#"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                v-on:click="getDataPagina(pagina)"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
function buscarEnObjecto(objecto, palabra) {
    for (let key in objecto) {
        if (
            objecto.hasOwnProperty(key) &&
            objecto[key].toString().toLowerCase().includes(palabra)
        ) {
            return true;
        }
    }

    return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            permisos: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
        };
    },
    computed: {
        searchPermiso: function () {
            return this.datos.filter((permiso) => {
                return buscarEnObjecto(permiso, this.busqueda.toLowerCase());
            });
        },
    },
    created() {
        this.axios.get("/api/permisosempleados", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.permisos = response.data;
            this.listLength = this.permisos.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deletePermisos(id) {
            this.axios
                .delete(`/api/permisosempleados/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    this.reload();
                });
            Swal.fire({
                title: "Permiso cancelado!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.permisos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.permisos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
