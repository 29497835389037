
<template>    
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto"> 
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Estado Nutricional</h3>
      <form @submit.prevent="addNutricion">
      <div class="form-group">
        <label>Fecha</label>
        <input
          type="date"
          class="form-control"
          v-model="nutricion.fecha"
          />
      </div>
      <div class="form-group">
        <label>Peso</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.peso"
        />
      </div>
      <div class="form-group">
        <label>Talla</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.talla"
        />
      </div>
      <div class="form-group">
        <label>Estatura</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.estatura"
        />
      </div>
      <button  type="submit" class="btnAgregar">Agregar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      nutriciones: [],
      joven:{},
      nutricion:{'joven': ''}, 
    };
  },
  created() {
      this.axios
      .get(`/api/nutriciones/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.nutriciones = response.data.nutriciones;
      this.joven = response.data.joven;
    });
  },
   methods: {
    addNutricion() {
      this.nutricion.joven = this.joven.id;
      this.axios
        .post("/api/nutriciones", this.nutricion, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
        Swal.fire({
        icon: 'success',
        title: 'Estado Nutricional Creado!',
        showConfirmButton: false,
        timer: 1500
      })
    },
    
  },
};
</script> 