<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px;">
                            <h3 class="title">Tipos de Eventos</h3>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                               <router-link to="/addTipos"  id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Tipo de Evento <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table class="table table-bordered table-striped table-condensed" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Nombre</th>
                            <th>Puntos</th>
                            <th>Tipo</th>
                            <th>Categoria</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="tipo in datos" :key="tipo.id">
                        <td>{{ tipo.nombre}}</td>
                        <td>{{ tipo.puntos }}</td>
                        <td>{{ tipo.tipo}}</td>
                        <td>{{ tipo.categoria}}</td>
                        <td>
                        <div class="btn-group" role="group">
                            <router-link
                            :to="{ name: 'editTipos', params: { id: tipo.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deleteTipos(tipo.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      tipos: [],
      pageSize:10,
      datos:[],
      paginaActual:1,
       listLength:0
    };
  },
  created() {
      this.axios
        .get(`/api/tipos`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
        this.tipos = response.data;
        this.listLength = this.tipos.length;
        this.getDataPagina(1);
    });
  },
   methods: {
    deleteTipos(id) {

            Swal.fire({
          title:'¿Desea continuar?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {

        this.axios
          .delete(`/api/tipos/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
          .then((response) => {
            let i = this.tipos.map((data) => data.id).indexOf(id);
            this.tipos.splice(i, 1);
            this.reload();
          });
      }
    });
    },
    totalPaginas(){
        return Math.ceil(this.tipos.length / this.pageSize)

     },
     getDataPagina(noPagina){

            this.paginaActual = noPagina;
            this.datos=[];
            let x = noPagina-1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.tipos.slice(ini,fin);


     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>