<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <router-link to="/egresos" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></router-link>
          <h3 class="title text-center mt-4">Egresos</h3>
          <form @submit.prevent="updateEgreso">
            <div class="row">
              <div class="form-group1">
                <label>Presupuesto</label>
                <input type="text" name="presupuesto" id="presupuesto" class="form-control" disabled />
              </div>
              <div class="form-group1">
                <label>Rubro</label>
                <input name="rubro" id='rubro' type="text" class="form-control" disabled />
              </div>
              <div class="form-group1">
                <label>Proveedor</label>
                <input name="proveedor" id='proveedor' type="text" class="form-control" disabled />
              </div>



            </div>
            <hr>
            <div class="row">
              <div class="form-group1">
                <label>Tipo</label>
                <select class="form-control" v-model="egreso.tipo">
                  <option value="Credito">Crédito</option>
                  <option value="Contado">Contado</option>
                </select>
              </div>

              <div class="form-group1">
                <label>Estado</label>
                <select class="form-control" v-model="egreso.estado">
                  <option value="Pendiente">Pendiente</option>
                  <option value="Pagado">Pagado</option>
                  <option value="Anulado">Anulado</option>
                </select>
              </div>
              <div class="form-group1">
                <label>Número del Documento</label>
                <input type="text" class="form-control" v-model="egreso.numero_documento" />
              </div>
            </div>
            <hr>
            <div class="row">

            </div>
            <div class="row">
              <div class="form-group1">
                <label>Fecha del Documento</label>
                <input type="date" class="form-control" v-model="egreso.fecha_documento" />
              </div>
              <div class="form-group1">
                <label>Fecha de Vencimiento</label>
                <input type="date" class="form-control" v-model="egreso.fecha_vencimiento" />
              </div>
              <div class="form-group1">
                <label>Monto</label>
                <input type="text" id="number" class="form-control" v-model="egreso.monto" />
              </div>
            </div>
            <button type="submit" class="btnAgregar">Editar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      egreso: {},
      rubros: [],
      presupuestos: [],
      proveedores: [],
    };
  },
  created() {
    this.axios
      .get(`/api/egresos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((res) => {
        this.egreso = res.data.egreso;
        this.presupuestos = res.data.presupuestos
        this.proveedores = res.data.proveedores
        this.rubros = res.data.rubros
        this.cargarRubro(this.egreso.rubro_Presupuesto);
        this.cargarPresupuesto(this.egreso.presupuesto);
        this.cargarproveedor(this.egreso.proveedor);
      });
  },
  methods: {
    updateEgreso() {
      this.axios
        .patch(
          `/api/egresos/${this.$route.params.id}`,
          this.egreso, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((response) => {

          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0]) === parseInt(1);
          if (x) {
            Swal.fire({
              title: 'Actualizado!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
          else {
            Swal.fire({
              title: 'No se puede Actualizar',
              text: 'El rubro no cuenta con el monto sufuciente',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500
            })
          }
          this.$router.push({ name: 'egresos' })
        });


    },
    numeros() {
      $("#number").on({
        "focus": function (event) {
          $(event.target).select();
        },
        "keyup": function (event) {
          $(event.target).val(function (index, value) {
            return value.replace(/\D/g, "")
              .replace(/([0-7])([0-7]{2})$/, '$1.$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "");
          });
        }
      });
    }, cargarRubro(id) {
      var nombres = '';
      for (var key in this.rubros) {
        for (var i in this.rubros[key]) {
          if (this.rubros[key].id == id) {

            nombres = this.rubros[key].nombre_rubro;
          }
        }
      }
      return $("#rubro").val(nombres);
    },
    cargarproveedor(id) {
      var nombres = '';
      for (var key in this.proveedores) {
        for (var i in this.proveedores[key]) {
          if (this.proveedores[key].id == id) {

            nombres = this.proveedores[key].nombre;
          }
        }
      }
      return $("#proveedor").val(nombres);
    },
    cargarPresupuesto(id) {
      var nombres = '';
      for (var key in this.presupuestos) {
        for (var i in this.presupuestos[key]) {
          if (this.presupuestos[key].id == id) {

            nombres = this.presupuestos[key].nombre;
          }
        }
      }
      return $("#presupuesto").val(nombres);
    },
  },
};
</script>