import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({


  state: {
    logoPath: require('@/assets/logo.png'),
    token: sessionStorage.getItem('token') || '',
    user: ""
  },


  getters: {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    getUser:  state => state.user,
    getUserRole: state => state.user ? state.user.role : null,
    getLogoPath:  state => state.logoPath,
  },


  mutations: {
    setLogo(state, logo) {
      state.logoPath = logo;
    },

    setToken(state, token) {
      state.token = token;
      sessionStorage.setItem('token', token);
    },

    setUser(state, user) {
      state.user = user; 
    },
    
    clearToken(state) {
      state.token = '';
      sessionStorage.removeItem('token');
    },

    clearUser(state) {
      state.user = null; // Mutación para limpiar los detalles del usuario
    }
  },


  actions: {
    // Logout
    async logout({ commit }) {
      try {
        await axios.post('/api/logout', {}, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        });
      } catch (error) {
        console.error('Error during logout:', error);
      } finally {
        commit('clearToken');
        commit('clearUser');
      }
    },
    // Login
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/api/login', credentials);
        const token = response.data.authorisation.token;
        const user = response.data.user;
        //console.log(token);
        //console.log(user);
        commit('setUser', user);
        commit('setToken', token);
      } catch (error) {
        console.error('Error during login:', error);
        throw error; // Re-throw the error so it can be handled in the component
      }
    }
  },
  plugins: [createPersistedState({
    paths: ['user'], // Solo persistir el estado 'user'
    key: 'user',
    storage: window.sessionStorage,
  })],
  modules: {
  }


})
