<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <router-link to="/colaboradores" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
        <h4 class="title">Colillas</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link to="/addColillas"  id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Colilla<i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table  id="colillas" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Número Factura</th>
                            <th>Presupuesto</th>
                            <th>Colaborador</th>
                            <th>Periodo</th>
                            <th>Salario</th>
                            <th>Deducciones</th>
                            <th>Total</th>
                            <th>Observaciones</th>
                            <th>Estado</th>


                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="colilla in datos" :key="colilla.id">
                        <td>{{ colilla.numero_Factura }}</td>
                        <td>{{ cargarPresupuesto(colilla.presupuesto) }}</td>
                        <td>{{ cargarColaborador(colilla.colaborador) }}</td>
                        <td>{{ colilla.periodo}}</td>
                        <td>₡{{ colilla.salario}}</td>
                        <td>₡{{ colilla.totalDeducciones}}</td>
                        <td>₡{{ colilla.neto}}</td>
                        <td>{{ colilla.observaciones}}</td>
                        <td>{{ colilla.estado}}</td>
                        <td>
                        <div class="btn-group" role="group" v-if="colilla.estado =='Pendiente'">
                          <button
                            class="btn btn-success"
                            @click="updateColilla(colilla.id)"
                            >
                            <i class="fas fa-check"></i>
                            </button>
                            <button
                            class="btn btn-danger"
                            @click="deleteColilla(colilla.id)"
                            >
                            <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      colillas: [],
      pageSize:10,
      datos:[],
      paginaActual:1,
      colaboradores:[],
      presupuestos:[],
      listLength:0
    };
  },
  created() {
    this.axios.get("/api/colillas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.colillas = response.data.colillas;
      this.colaboradores = response.data.colaboradores;
      this.presupuestos = response.data.presupuestos;
      this.listLength = this.colillas.length;
      this.getDataPagina(1);
    });
  },
   methods: {
    deleteColilla(id) {
                    Swal.fire({
          title:'¿Desea continuar?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {
      this.axios
        .delete(`/api/colillas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.colillas.map((data) => data.id).indexOf(id);
          this.colillas.splice(i, 1);
          this.reload();
        });
      }
    });

    },
    updateColilla(id) {
      this.axios
      .patch(
          `/api/colillas/${id}`,
          id, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((response) => {

          Swal.fire({
          title: 'Pagado!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
          })
          this.reload();
        });
    },
 totalPaginas(){
       return Math.ceil(this.colillas.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.colillas.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     },
     cargarColaborador(id){
       var nombres = '';
       for(var key in this.colaboradores){
         for(var i in this.colaboradores[key]){
          if(this.colaboradores[key].id == id){

            nombres = this.colaboradores[key].nombre;
          }
         }
       }
       return nombres;
    },
    cargarPresupuesto(id){
       var nombres = '';
       for(var key in this.presupuestos){
         for(var i in this.presupuestos[key]){
          if(this.presupuestos[key].id == id){

            nombres = this.presupuestos[key].nombre;
          }
         }
       }
       return nombres;
    },
  },
};
</script>