<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10">
                            <h3 class="title">Eventos</h3>
                        </div>
                    </div>
                    <div class="row" v-if="this.$route.params.jovenId">
                        <div class="col">
                            <p class="text-dark">
                                Eventos para {{ this.eventos[0].nombre }}.
                                <router-link class="text-dark" :to="{ name: 'eventos' }">Ver todos</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-3">
                            <label>Tipo</label>
                            <select class="form-control" v-model="tipoFilter" name="tipoFilter" id="tipoFilter"
                                v-on:change="loadEvents">
                                <option v-for="tipo in this.tipos" :key="tipo.id" :value="tipo.id">
                                    {{ tipo.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-4">
                            <label>Rango de Fechas</label>
                            <input type="date" v-model="startDate" class="form-control" required
                                pattern="\d{4}-\d{2}-\d{2}" v-on:change="loadEvents" />
                            <input type="date" v-model="endDate" class="form-control" required
                                pattern="\d{4}-\d{2}-\d{2}" v-on:change="loadEvents" />
                        </div>
                        <div class="col-sm-3 col-xs-10">
                            <label>Nombre de PME</label>
                            <input type="text" v-model="busqueda" id="busqueda" class="form-control"
                                placeholder="Nombre" />
                        </div>
                        <div class="col-sm-2 col-xs-2 text-right">
                            <div v-show="userRole === 'Administrador' || userRole === 'Nivel1' || userRole === 'SuperAdministrador'">
                                <div class="btn_group">
                                    <router-link to="/addEventos" id="agregar" class="btn btn-info pull-left" style="
                                            margin-top: -7px;
                                            margin-left: 2px;
                                        ">Crear Evento <i class="fas fa-plus"></i></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table class="
                            table table-bordered table-striped table-condensed
                        " v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th>Categoria</th>
                                <th>Tipo</th>
                                <th>Joven</th>
                                <th>Puntaje</th>
                                <th>Descripción</th>
                                <th>Día</th>
                                <th>Hora</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="evento in searchEvento" :key="evento.id">
                                <td>{{ evento.categoria }}</td>
                                <td>{{ evento.tipo }}</td>
                                <td>{{ evento.nombre }}</td>
                                <td>{{ evento.puntaje }}</td>
                                <td>{{ evento.descripcion }}</td>
                                <td>{{ evento.fecha }}</td>
                                <td>{{ evento.hora }}</td>
                                <div v-show="userRole === 'Administrador' ||
                                    userRole === 'Nivel1'
                                    ">
                                    <td>
                                        <div class="btn-group" role="group">
                                            <router-link :to="{
                                                name: 'editEventos',
                                                params: { id: evento.id },
                                            }" class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                                            <button class="btn btn-danger" @click="
                                                deleteEventos(evento.id)
                                                ">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#"></a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

function buscarEnObjecto(objecto, palabra) {
    for (let key in objecto) {
        if (
            objecto.hasOwnProperty(key) &&
            objecto[key].toString().toLowerCase().includes(palabra)
        ) {
            return true;
        }
    }

    return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            eventos: [],
            pageSize: 40,
            datos: [],
            jovenes: [],
            tipos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
            startDate: new Date(Date.now() - 12096e5).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            tipoFilter: ''
        };
    },
    created() {
        if (this.$route.params.jovenId) {
            this.axios.get(`/api/eventos?jovenId=${this.$route.params.jovenId}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    this.eventos = response.data.eventos;
                    this.tipos = response.data.tipos;
                    this.jovenes = response.data.jovenes;
                    this.listLength = this.eventos.length;
                    this.getDataPagina(1);
                }).catch(error => {
                    console.log(error);
                });
        } else {
            this.axios.get(`/api/eventos?startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.eventos = response.data.eventos;
                this.tipos = response.data.tipos;
                this.jovenes = response.data.jovenes;
                this.listLength = this.eventos.length;
                this.getDataPagina(1);
            });
        }
    },
    computed: {
        searchEvento: function () {
            return this.datos.filter((eventos) => {
                return buscarEnObjecto(eventos, this.busqueda.toLowerCase());
            });
        },
        userRole() {
            return this.$store.getters.getUserRole;
        }
    },
    methods: {
        deleteEventos(id) {
            Swal.fire({
                title: "¿Desea continuar?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Delete",
                icon: "error",
            }).then((res) => {
                if (res.value) {
                    this.axios.delete(`/api/eventos/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        let i = this.eventos.map((data) => data.id).indexOf(id);
                        this.eventos.splice(i, 1);
                        this.reload();
                    });
                }
            });
        },
        totalPaginas() {
            return Math.ceil(this.eventos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.eventos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
        cargarTipo(id) {
            var nombres = "";
            for (var key in this.tipos) {
                for (var i in this.tipos[key]) {
                    if (this.tipos[key].id == id) {
                        nombres = this.tipos[key].nombre;
                    }
                }
            }
            return nombres;
        },
        cargarJoven(id) {
            var nombres = "";
            for (var key in this.jovenes) {
                for (var i in this.jovenes[key]) {
                    if (this.jovenes[key].id == id) {
                        nombres = this.jovenes[key].nombre;
                    }
                }
            }
            return nombres;
        },
        loadEvents: function () {
            this.axios.get(`/api/eventos?tipo=${this.tipoFilter}&startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.eventos = response.data.eventos;
                this.tipos = response.data.tipos;
                this.jovenes = response.data.jovenes;
                this.listLength = this.eventos.length;
                this.getDataPagina(1);
            });
        }
    },
};
</script>
