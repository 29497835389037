<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link
                        to="/vacacionesadm"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></router-link>
                    <h3 class="title text-center mt-4">
                        Revision de vacaciones
                    </h3>
                    <form @submit.prevent="updateVacacionesAdm">
                        <div class="form-group">
                            <label>Usuario</label>
                            <input
                                type="text"
                                class="form-control"
                                disabled="true"
                                v-model="vacacion.nombre"
                            />
                        </div>
                        <div class="form-group">
                            <label>Desde</label>
                            <input
                                type="text"
                                class="form-control"
                                disabled="true"
                                v-model="vacacion.desde"
                            />
                        </div>
                        <div class="form-group">
                            <label>Hasta</label>
                            <input
                                type="text"
                                class="form-control"
                                disabled="true"
                                v-model="vacacion.hasta"
                            />
                        </div>
                        <div class="form-group">
                            <label>Comentarios del colaborador</label>
                            <textarea
                                class="form-control"
                                disabled="true"
                                v-model="vacacion.comentarios"
                            ></textarea>
                        </div>
                        <div class="row justify-content-center">
                            <div class="btn-group" role="group">
                                <button
                                    class="btn btn-success"
                                    style="margin-right:5%"
                                    v-on:click="callFunction"
                                    data-id="Aprobada"
                                    value="Aprobada"
                                >
                                    Aprobar
                                </button>
                                <button
                                    class="btn btn-danger"
                                    v-on:click="callFunction"
                                    style="margin-left:5%"
                                    data-id="Rechazada"
                                    value="Rechazada"
                                >
                                    Rechazar
                                </button>
                            </div>
                        </div>

                        <!-- <button type="submit" class="btnAgregar">Editar</button> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            vacacion: {},
            usuario: {},
        };
    },
    created() {
        this.axios
            .get(`/api/vacacionesadm/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((res) => {
                this.vacacion = res.data.vacaciones;
                this.usuario = res.data.usuario;
            });
    },
    methods: {
        updateVacacionesAdm() {
            this.axios
                .patch(
                    `/api/vacacionesadm/${this.$route.params.id}`,
                    this.vacacion, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
                )
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Datos Actualizados!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
        },
        callFunction: function (event) {
            var id = event.target.getAttribute("data-id");
            this.vacacion.estado = id
            return vacacion.estado;
        },
    },
};
</script>
