<template>
  <div class="container">
    <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <router-link to="/presupuestos" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
        <h3 class="title text-center mt-4">Presupuesto</h3>
          <form @submit.prevent="updatePresupuesto">
            <div class="form-group" >
              <label>Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="presupuesto.nombre"
              />
            </div>
            <div class="form-group" >
              <label>Fecha de Inicio</label>
              <input
                type="date"
                class="form-control"
                v-model="presupuesto.fecha_Inicio"
              />
            </div>
            <div class="form-group" >
              <label>Fecha Fin</label>
              <input
                type="date"
                class="form-control"
                v-model="presupuesto.fecha_Fin"
              />
            </div>
            <div class="form-group" >
              <label>Organización</label>
              <input
                type="text"
                class="form-control"
                name="organizacion"
                id="organizacion"
                disabled
              />
            </div>
            <div class="form-group" >
              <label>Monto</label>
              <input
                placeholder="₡"
                type="text"
                class="form-control"
                 v-model="presupuesto.monto"
              />
            </div>
            <button type="submit" class="btnAgregar">Editar</button>
          </form>
          <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Rubros</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                              <router-link :to="{ name: 'addRubro', params: { id: presupuesto.id } }" id="agregar"  class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Agregar Rubros <i class="fas fa-plus"></i></router-link>
                             </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                            <th>Nombre</th>
                            <th>Alias</th>
                            <th>Monto</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="rubro in datos" :key="rubro.id">
                        <td>{{ rubro.nombre_rubro }}</td>
                        <td>{{ rubro.alias_rubro }}</td>
                        <td>{{ rubro.monto_rubro }}</td>
                        <td>
                        <div class="btn-group" role="group">
                            <router-link
                            :to="{ name: 'editRubro', params: { id: rubro.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deleteRubro(rubro.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"><</a></li>
                      <li v-for="pagina in totalPaginas()" v-bind:key="isActive(pagina)" v-on:click="getDataPagina(pagina)" class="page-item"  ><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
        </div>

      </div>

    </div>

  </div>


</template>

<script>
export default {
  inject:['reload'],
  data() {
    return {
      presupuesto: {},
      presupuestos_rubros:[],
      organizaciones:[],
      pageSize:15,
      datos:[],
      paginaActual:1,
    };

  },
  created() {
    this.axios
      .get(`/api/presupuestos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((res) => {
        this.presupuestos_rubros = res.data.rubros;
        this.presupuesto = res.data.presupuesto;
        this.organizaciones = res.data.organizaciones;
        this.getDataPagina(1);
        this.cargarOrganizacion(this.presupuesto.organizacion);
      });
  },
  methods: {
    updatePresupuesto() {
      this.axios
        .patch(
          `/api/presupuestos/${this.$route.params.id}`,
          this.presupuesto, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((response) => {
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Ingrese todos los datos',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            icon: 'success',
            title: 'Presupuesto Actualizado!',
            showConfirmButton: false,
            timer: 1000
          })

          }

        });

    },
    deleteRubro(id) {
      this.axios
        .delete(`/api/rubros/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          var resultado = response.data.resultado['resultado'];
          let i = this.presupuestos_rubros.map((data) => data.id).indexOf(id);
          this.presupuestos_rubros.slice(i,1)
          location.reload();
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Eliminado!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            title: 'No se puede Eliminar',
            text: 'El rubro tiene egresos asociados',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })
          }
        });
    },
  totalPaginas(){
       return Math.ceil(this.presupuestos_rubros.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.presupuestos_rubros.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     },
     cargarOrganizacion(id){
       var nombres = '';
       for(var key in this.organizaciones){
         for(var i in this.organizaciones[key]){
          if(this.organizaciones[key].id == id){
            nombres = this.organizaciones[key].nombre;
          }
         }
       }
       return $("#organizacion").val(nombres);
    },
  }
};
</script>