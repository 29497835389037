<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col">
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col text-center"
                            style="margin-left: 5%"
                            v-if="listLength != 0"
                        >
                            <label id="reportes">Joven</label>
                            <select
                                id="joven"
                                style="
                                    width: 200px;
                                    height: 29px;
                                    border-radius: 5px;
                                "
                            >
                                <option
                                    id="datos"
                                    v-for="joven in jovenes"
                                    :key="joven.id"
                                    :value="joven.id"
                                    v-text="joven.nombre"
                                ></option>
                            </select>
                        </div>
                        <div class="col">
                            <label style="margin-left: 10px"
                                >Fecha Inicio</label
                            >
                            <input
                                type="date"
                                id="fecha_inicio"
                                style="border-radius: 5px; width: 150px"
                            />
                        </div>
                        <div class="col">
                            <label style="margin-left: 15px">Fecha Final</label>
                            <input
                                type="date"
                                id="fecha_final"
                                style="border-radius: 5px; width: 150px"
                            />
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                style="width: 150px"
                                @click="buscar()"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                style="
                                    background-color: #fec107;
                                    border-color: #fec107;
                                    width: 150px;
                                "
                                class="btn btn-danger"
                                @click="generateReport()"
                            >
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Categoría</th>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td class="text-center">{{ reporteL.categoria }}</td>
                                <td class="text-center">{{ reporteL.nombre }}</td>
                                <td class="text-center">{{ reporteL.detalle }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Expediente médico"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="1000px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style="
                                color: black;
                                padding-left: 13%;
                                padding-top: 4%;
                            "
                            class="
                                align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2 class="text-center" style="margin-left: 5%; margin-bottom: 4%;">
                                Expediente médico
                            </h2>
                            <div class="panel-body table-responsive">
                                <NoExist v-if="listLength == 0" />
                                <table
                                    class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    "
                                    v-if="listLength != 0"
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Categoría</th>
                                            <th class="text-center">Nombre</th>
                                            <th class="text-center">Descripción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td class="text-center">{{ reporteL.categoria }}</td>
                                            <td class="text-center">{{ reporteL.nombre }}</td>
                                            <td class="text-center">{{ reporteL.detalle }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            jovenes: [],
            vacunas: [],
            citas: [],
            dolencias: [],
            laboratorios: [],
            padecimientos: [],
            evoluciones: [],
            cirugias: [],
            recetas: [],
            periodo: [],
            nutriciones: [],
            epicrisis: [],
            datos: {},
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/expediente`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.jovenes = response.data.jovenes;
            this.vacunas = response.data.vacunas;
            this.citas = response.data.citas;
            this.dolencias = response.data.dolencias;
            this.laboratorios = response.data.laboratorios;
            this.padecimientos = response.data.padecimientos;
            this.evoluciones = response.data.evoluciones;
            this.cirugias = response.data.cirugias;
            this.recetas = response.data.recetas;
            this.periodos = response.data.periodos;
            this.nutriciones = response.data.nutriciones;
            this.epicrisis = response.data.epicrisis;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            var joven = document.getElementById("joven").value;
            var fecha_inicio = document.getElementById("fecha_inicio").value;
            var fecha_final = document.getElementById("fecha_final").value;
            var aux = new Array();
            for (var key in this.vacunas) {
                if (this.vacunas[key].joven == joven) {
                    if (
                        this.vacunas[key].created_at >= fecha_inicio &&
                        this.vacunas[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.vacunas[key].nombre;
                        aux2.categoria = "Vacuna";
                        var fecha = new Date(this.vacunas[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.vacunas[key].detalle;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.citas) {
                if (this.citas[key].joven == joven) {
                    if (
                        this.citas[key].created_at >= fecha_inicio &&
                        this.citas[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.citas[key].nombre;
                        aux2.categoria = "Cita";
                        var fecha = new Date(this.citas[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.citas[key].detalle;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.dolencias) {
                if (this.dolencias[key].joven == joven) {
                    if (
                        this.dolencias[key].created_at >= fecha_inicio &&
                        this.dolencias[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.dolencias[key].nombre;
                        aux2.categoria = "Dolencia";
                        var fecha = new Date(this.dolencias[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.dolencias[key].descripcion;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.laboratorios) {
                if (this.laboratorios[key].joven == joven) {
                    if (
                        this.laboratorios[key].created_at >= fecha_inicio &&
                        this.laboratorios[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.laboratorios[key].resultado;
                        aux2.categoria = "Laboratorio";
                        var fecha = new Date(this.laboratorios[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.laboratorios[key].detalle;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.padecimientos) {
                if (this.padecimientos[key].joven == joven) {
                    if (
                        this.padecimientos[key].created_at >= fecha_inicio &&
                        this.padecimientos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.padecimientos[key].nombre;
                        aux2.categoria = "Padecimiento";
                        var fecha = new Date(
                            this.padecimientos[key].created_at
                        );
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.padecimientos[key].descripcion;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.evoluciones) {
                if (this.evoluciones[key].joven == joven) {
                    if (
                        this.evoluciones[key].created_at >= fecha_inicio &&
                        this.evoluciones[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Evolución Medica";
                        var fecha = new Date(this.evoluciones[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.evoluciones[key].diagnostico;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.cirugias) {
                if (this.cirugias[key].joven == joven) {
                    if (
                        this.cirugias[key].created_at >= fecha_inicio &&
                        this.cirugias[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.cirugias[key].nombre;
                        aux2.categoria = "Cirugia";
                        var fecha = new Date(this.cirugias[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.cirugias[key].descripcion;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.recetas) {
                if (this.recetas[key].joven == joven) {
                    if (
                        this.recetas[key].created_at >= fecha_inicio &&
                        this.recetas[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.recetas[key].medicamento;
                        aux2.categoria = "Receta";
                        var fecha = new Date(this.recetas[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.recetas[key].frecuencia;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.periodos) {
                if (this.periodos[key].joven == joven) {
                    if (
                        this.periodos[key].created_at >= fecha_inicio &&
                        this.periodos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Periodo";
                        var fecha = new Date(this.periodos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = "Registrado";
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.nutriciones) {
                if (this.nutriciones[key].joven == joven) {
                    if (
                        this.nutriciones[key].created_at >= fecha_inicio &&
                        this.nutriciones[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = this.nutriciones[key].peso;
                        aux2.categoria = "Estado Nutricional";
                        var fecha = new Date(this.nutriciones[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = "Registrado";
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.epicrisis) {
                if (this.epicrisis[key].joven == joven) {
                    if (
                        this.epicrisis[key].created_at >= fecha_inicio &&
                        this.epicrisis[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Epicrisis";
                        var fecha = new Date(this.epicrisis[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = this.epicrisis[key].detalle;
                        aux.push(aux2);
                    }
                }
            }
            this.datos = aux;
        },
    },
};
</script>
