<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link
                        to="/vacacionesempleados"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></router-link>
                    <h3 class="title text-center mt-4">
                        Solicitud de vacaciones
                        <i class="fa-solid fa-person-walking-luggage"></i>
                    </h3>

                    <form @submit.prevent="addVacacionesEmpleados">
                        <div
                            class="row"
                            style="margin-bottom: 4%; margin-top: 3%"
                        >
                            <div class="col">
                                <label>Desde el</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="vacacion.desde"
                                    required
                                />
                            </div>
                            <div class="col">
                                <label>Hasta el</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="vacacion.hasta"
                                    required
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label>Comentarios</label>
                                <textarea
                                    type="text"
                                    class="form-control"
                                    v-model="vacacion.comentarios"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btnAgregar">
                                    Solicitar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            vacaciones: [],
            vacacion: {},
            colaboradores: [],
        };
    },
    created() {
        this.axios.get("/api/vacacionesempleados", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.vacaciones = response.data.vacaciones;
            this.colaboradores = response.data.colaboradores;
        });
    },
    methods: {
        addVacacionesEmpleados() {
            let formData = new FormData();
            formData.append("desde", this.vacacion.desde);
            formData.append("hasta", this.vacacion.hasta);
            formData.append("comentarios", this.vacacion.comentarios);
            this.axios
                .post("/api/vacacionesempleados", formData, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    var y = parseInt(resultado[0]) === parseInt(3);
                    var z = parseInt(resultado[0]) === parseInt(4);
                    var w = parseInt(resultado[0]) === parseInt(5);
                    var v = parseInt(resultado[0]) === parseInt(6);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else if (y) {
                        Swal.fire({
                            title: "La fecha de salida a vacaciones no puede ser menor o igual a la fecha actual",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else if (z) {
                        Swal.fire({
                            title: "Debe seleccionar correctamente las fechas",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 2500,
                        });
                    } else if (w) {
                        Swal.fire({
                            title: "Cantidad de días no disponibles",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else if (v) {
                        Swal.fire({
                            title: "Ya tienes días de vacaciones en estás fechas, intenta con otras fechas",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Vacaciones solicitadas!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
        },
    },
};
</script>
