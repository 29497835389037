<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-4 col-xs-6">
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-sm-4 col-xs-6">
                            <h4 class="title">Detalle de aguinaldo</h4>
                            <h5 style="color: black; font-size: 16px">
                                Colaborador: {{ colaborador.nombre }}
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        id="colaboradores"
                        class="table table-striped"
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Periodo</th>
                                <th class="text-center">Salario Bruto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="recibo in datos" :key="recibo.id">
                                <td class="text-center">
                                    {{ recibo.periodo }}
                                </td>
                                <td class="text-center">
                                    ₡{{ recibo.total_bruto }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav
                        aria-label="Page navigation example"
                        v-if="listLength != 0"
                    >
                        <ul class="pagination justify-content-center">
                            <li
                                class="page-item"
                                v-on:click="getPreviousPage()"
                            >
                                <a class="page-link" href="#"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                v-on:click="getDataPagina(pagina)"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            recibos: [],
            colaborador: {},
            pageSize: 5,
            datos: [],
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios
            .get(`/api/aguinaldos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.recibos = response.data.nominas;
                this.colaborador = response.data.colaborador;
                this.listLength = this.recibos.length;
                this.getDataPagina(1);
            });
    },
    methods: {
        totalPaginas() {
            return Math.ceil(this.recibos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.recibos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
