<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel noExist" >
                <h1 style="color:black">No hay elementos para mostrar</h1>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>