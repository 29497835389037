<template>
  <div class="row">
    <div class="col-md-offset-1 col-md-12">
      <div class="panel">
        <div class="panel-heading">
          <div class="row">
            <div class="col col-sm-3 col-xs-12">
              <h4 class="title">Colaboradores</h4>
            </div>
            <div class="col-sm-9 col-xs-12 text-right">
              <div class="btn_group">
                <router-link to="/addColaboradores" id="agregar" class="btn btn-info pull-left"
                  style="margin-top:-7px;margin-left:2px;">Crear Colaborador <i class="fas fa-plus"></i></router-link>
                <router-link to="/colillas" id="agregar1" class="btn btn-info pull-left"
                  style="margin-top:-7px;margin-left:2px;">Colillas <i class="fas fa-plus"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body table-responsive">
          <NoExist v-if="listLength == 0" />
          <table id="colaboradores" class="table table-striped" v-if="listLength != 0">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cédula</th>
                <th>Correo</th>
                <th>Teléfono</th>
                <th>Puesto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="colaborador in datos" :key="colaborador.id">
                <td>{{ colaborador.nombre }}</td>
                <td>{{ colaborador.cedula }}</td>
                <td>{{ colaborador.correo }}</td>
                <td>{{ colaborador.telefono }}</td>
                <td>{{ colaborador.puesto }}</td>
                <td>
                  <div class="btn-group" role="group">
                    <router-link :to="{ name: 'editColaboradores', params: { id: colaborador.id } }"
                      class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                    <button class="btn btn-danger" @click="deleteColaborador(colaborador.id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <router-link :to="{ name: 'eventosColaboradores', params: { id: colaborador.id } }"
                      class="btn btn-danger" id="botonJovenes"> <i class="fas fa-plus"></i></router-link>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example" v-if="listLength != 0">
            <ul class="pagination justify-content-center">
              <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
              <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{ pagina }}</a></li>
              <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {

  inject: ['reload'],
  components: {
    NoExist
  },
  data() {
    return {
      colaboradores: [],
      pageSize: 5,
      datos: [],
      paginaActual: 1,
      listLength: 0
    };
  },
  created() {
    this.axios.get("/api/colaboradores", {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    })
      .then((response) => {
        this.colaboradores = response.data.colaboradores;
        this.listLength = this.colaboradores.length;
        this.getDataPagina(1);
      });
  },
  methods: {
    deleteColaborador(id) {
      Swal.fire({
        title: '¿Desea continuar?',
        confirmButtonColor: "red",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "error"
      }).then(res => {
        if (res.value) {
          this.axios
            .delete(`/api/colaboradores/${id}`, {
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
              }
            })
            .then((response) => {
              let i = this.colaboradores.map((data) => data.id).indexOf(id);
              this.colaboradores.splice(i, 1);
              this.reload();
            });
        }
      });
    },
    totalPaginas() {
      return Math.ceil(this.colaboradores.length / this.pageSize)
    },
    getDataPagina(noPagina) {
      this.paginaActual = noPagina;
      this.datos = [];
      let x = noPagina - 1;
      let ini = x * this.pageSize;
      let fin = noPagina * this.pageSize;
      this.datos = this.colaboradores.slice(ini, fin);
    },
    getPreviousPage() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage() {
      if (this.paginaActual < this.totalPaginas()) {
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina) {
      return noPagina == this.paginaActual ? 'active' : '';
    }
  },
};
</script>