
<template>    
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto"> 
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Padecimientos</h3>
      <form @submit.prevent="updatePadecimientos">
      <div class="form-group">
        <label>Nombre</label>
        <input
          type="text"
          class="form-control"
          v-model="padecimiento.nombre"
          />
      </div>
      <div class="form-group">
        <label>Descripción</label>
        <input
          type="text"
          class="form-control"
          v-model="padecimiento.descripcion"
        />
      </div>
      <div class="form-group">
        <label>Tratamiento</label>
        <input
          type="text"
          class="form-control"
          v-model="padecimiento.tratamiento"
        />
      </div>
      <button  type="submit" class="btnAgregar">Editar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      padecimiento:{}, 
    };
  },
  created() {
      this.axios
      .get(`/api/padecimientos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.padecimiento = response.data.padecimiento;
    });
  },
   methods: {
    updatePadecimientos() {
      this.axios
        .patch(
          `/api/padecimientos/${this.$route.params.id}`,
          this.padecimiento, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((res) => {
          this.$router.go(0);
        });
        Swal.fire({
        icon: 'success',
        title: 'Padecimiento Actualizado!',
        showConfirmButton: false,
        timer: 1500
      })
    },
    
  },
};
</script> 