var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-4"},[_c('a',{staticClass:"btn btn-info pull-right",attrs:{"id":"volver"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"})])])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-lg-12 col-xl-12 mx-auto"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('img',{staticClass:"imagenF",attrs:{"width":"100","height":"100","src":`${_vm.filebaseurl}/jovenes/${_vm.joven.foto}`,"alt":""}}),_c('h3',{staticClass:"title text-center",staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(_vm.joven.nombre)+" ")])])]),_c('div',{staticClass:"row flex-row"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'expedientes',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Expediente Educativo")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'titulos', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-graduation-cap"}),_vm._v(" Títulos")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'citas', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-book-medical"}),_vm._v(" Citas")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'recetas', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v(" Recetas ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'evoluciones',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-file-medical"}),_vm._v(" Evolución Médica ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'nutriciones',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-weight"}),_vm._v(" Estado Nutricional")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'vacunas', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-syringe"}),_vm._v(" Vacunas ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'personales',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-user-friends"}),_vm._v(" Personal Acompañante")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'laboratorios',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-vials"}),_vm._v(" Laboratorios ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'epicrisis',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-folder-plus"}),_vm._v(" Epicrisis")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'periodos', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-female"}),_vm._v(" Periodo Menstrual")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'padecimientos',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-thermometer-three-quarters"}),_vm._v(" Padecimientos ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'dolencias',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-procedures"}),_vm._v(" Dolencias")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'cirugias', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-stethoscope"}),_vm._v(" Cirugías")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{
                            name: 'pertenencias',
                            params: { id: _vm.joven.id },
                        },"id":"jovenes"}},[_c('i',{staticClass:"fas fa-shirt"}),_vm._v(" Pertenencias")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'llamadas', params: { id: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fa-solid fa-phone"}),_vm._v(" Llamadas ")]),_c('router-link',{staticClass:"btn btnJovenes",attrs:{"to":{ name: 'eventosByJoven', params: { jovenId: _vm.joven.id } },"id":"jovenes"}},[_c('i',{staticClass:"fa-solid fa-calendar"}),_vm._v(" Eventos ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }