<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/colaboradores" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Colaboradores</h3>
                    <form @submit.prevent="addColaborador">
                        <div class="row">
                            <div class="form-group1 col">
                                <label>Nombre</label>
                                <input type="text" class="form-control" v-model="colaborador.nombre" />
                            </div>
                            <div class="form-group1 col">
                                <label>Fecha de Nacimiento</label>
                                <input type="date" class="form-control" v-model="colaborador.fecha_Nacimiento" />
                            </div>

                            <div v-if="$route.params.id" class="form-group1">
                                <label>Fecha de Ingreso</label>
                                <input type="date" class="form-control" v-model="colaborador.fecha_Ingreso" />
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group1 col">
                                <label>Correo</label>
                                <input type="email" class="form-control" v-model="colaborador.correo" />
                            </div>
                            <div class="form-group1 col">
                                <label>Teléfono</label>
                                <input type="text" class="form-control" v-model="colaborador.telefono" />
                            </div>

                            <div class="form-group1 col">
                                <label>Puesto</label>
                                <select class="form-control" v-model="colaborador.puesto">
                                    <option v-for="option in dropdownOptions" :key="option.value" :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>


                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group1 col">
                                <label>Salario mensual</label>
                                <input type="text" class="form-control" v-model="colaborador.salario" />
                            </div>
                            <div v-if="$route.params.id" class="form-group1">
                                <label>Disponibilidad de Vacaciones</label>
                                <input type="text" class="form-control" v-model="colaborador.disponibilidad" />
                            </div>
                            <div class="form-group1 col">
                                <label>Cédula</label>
                                <input type="text" class="form-control" v-model="colaborador.cedula" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="float-left">
                                <div class="form-group1 col">
                                    <label>Foto</label>
                                    <input type="file" class="form-control" @change="obtenerImagen" />
                                </div>
                            </div>
                            <div class="form-group1 col">
                                <div class="float-right">
                                    <figure>
                                        <img class="foto" :src="imagenes" width="250" height="150" alt="Foto" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" class="btnAgregar">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            colaboradores: [],
            colaborador: {},
            imagen: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
            imagenMini: "",
            //Puesto Options
            addDropdownOptions: [ // ADD Options
                { value: 'ASI', text: 'ASI' },
                { value: 'Administrativo', text: 'Personal Administrativo' },
                { value: 'Pasante', text: 'Pasante' }
            ],
            updateDropdownOptions: [ // UPDATE Options
                { value: 'Tía', text: 'Tía' },
                { value: 'Contabilidad', text: 'Contabilidad' },
                { value: 'Pasante', text: 'Pasante' }
            ]
        };
    },
    created() {
        if (this.$route.params.id) {
            this.axios
                .get(`/api/colaboradores/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((res) => {
                    this.colaborador = res.data.colaborador;
                });
        } else {
            this.axios.get("/api/colaboradores", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.colaboradores = response.data.colaboradores;
            });
        }
    },
    methods: {
        obtenerImagen(event) {
            let file = event.target.files[0];
            this.imagen.archivo = file;
            this.cargarImagen(file);
        },
        cargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenMini = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        addColaborador() {
            let formData = new FormData();
            // UPDATE
            if (this.$route.params.id) {
                formData.append('foto', this.imagen.archivo);
                formData.append('nombre', this.colaborador.nombre);
                formData.append('fecha_Nacimiento', this.colaborador.fecha_Nacimiento);
                formData.append('fecha_Ingreso', this.colaborador.fecha_Ingreso);
                formData.append('correo', this.colaborador.correo);
                formData.append('telefono', this.colaborador.telefono);
                formData.append('disponibilidad', this.colaborador.disponibilidad);
                formData.append('puesto', this.colaborador.puesto);
                formData.append('salario', this.colaborador.salario);
                formData.append('cedula', this.colaborador.cedula);
                formData.append('_method', 'PATCH')
                this.axios
                    .post(
                        `/api/colaboradores/${this.$route.params.id}`,
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        var resultado = response.data.resultado['resultado'];
                        var x = parseInt(resultado[0]) === parseInt(1);
                        if (x) {
                            Swal.fire({
                                title: 'Ingrese todos los datos',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Colaborador Actualizado!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.reload();
                        }
                    });
            } else {
                // ADD
                formData.append("foto", this.imagen.archivo);
                formData.append("nombre", this.colaborador.nombre);
                formData.append("fecha_Nacimiento", this.colaborador.fecha_Nacimiento);
                formData.append("correo", this.colaborador.correo);
                formData.append("telefono", this.colaborador.telefono);
                formData.append("puesto", this.colaborador.puesto);
                formData.append("salario", this.colaborador.salario);
                formData.append("cedula", this.colaborador.cedula);
                this.axios.post("/api/colaboradores", formData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                }).then((response) => {
                    console.log(response.data);
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Colaborador Creado!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
            }
        },
    },
    computed: {
        imagenes() {
            return this.imagenMini;
        },
        dropdownOptions() {
            if (this.$route.params.id) {
                return this.updateDropdownOptions;
            } else {
                return this.addDropdownOptions;
            }
        },
    },
};
</script>
