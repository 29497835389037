
<template>    
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto"> 
                <div class="card-body">
                    <router-link to="/asociados" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Asociados</h3>
                    <form @submit.prevent="crear">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" v-model="asociado.nombre" required >
                        </div>
                        <div class="form-group"> 
                            <label>Apellidos</label> 
                            <input type="text" class="form-control" v-model="asociado.apellido" required>
                        </div>
                        <div class="form-group"> 
                            <label>Cédula</label> 
                            <input type="text" class="form-control" v-model="asociado.cedula" required>
                        </div>
                        <div class="form-group"> 
                            <label>Teléfono</label> 
                            <input type="text" class="form-control" v-model="asociado.telefono" required>
                        </div>
                        <div class="form-group"> 
                            <label>Monto</label> 
                            <input type="number" class="form-control" v-model="asociado.monto" required>
                        </div>
                        <div class="form-group"> 
                            <label>Periodo</label> 
                            <select class="form-control" v-model="asociado.id_periodo" required>
                                <option v-for="metodo in metodosPago" :key="metodo.id" :value="metodo.id">{{metodo.metodo}} </option>
                            </select>
                        </div>
                        <div class="form-group"> 
                            <label>Modo de pago</label> 
                            <select class="form-control" v-model="asociado.id_modo_pago" required>
                                <option v-for="modo in modosPago" :key="modo.id" :value="modo.id">{{modo.modo}} </option>
                            </select>
                        </div>
                        <div class="form-group"> 
                            <label>Fecha de último pago</label> 
                            <input  type="date" class="form-control" v-model="asociado.fecha_ultimo_pago"  required>
                            
                        </div>
                        <div class="form-group"> 
                            <label>Correo</label> 
                            <input id="email" type="email" class="form-control" v-model="asociado.correo" />
                        </div>
                        <div class="form-group">
                            <label>Dirección</label>
                            <input type="text" class="form-control" v-model="asociado.direccion"/>
                        </div>
                        <div class="form-group">
                            <label>Estado</label>
                            <input type="checkbox" class="form-control" v-model="asociado.estado"/>
                        </div>
                        <div class="form-group">
                            <label>Otras señas</label>
                            <textarea  type="text" class="form-control" v-model="asociado.otras_Senias" rows="3"></textarea>
                        </div>

                        <button  type="submit" class="btnAgregar">Agregar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {  
    inject:['reload'],
    data() {
        return {
            asociados: [],
            asociado:{},
            editar:false,
            metodosPago:[],
            modosPago:[],
        };
    },
    created() {
        this.axios.get(`/api/metodos-pago`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.metodosPago = response.data;
        });
        
        this.axios.get(`/api/modos-pago`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.modosPago = response.data;
        });
        


        let currentRoute = this.$route.path;
        const arrayRoute = currentRoute.split("/");
        if(arrayRoute[1] == 'editAsociado'){
            this.axios.get(`/api/asociados/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.asociado = response.data;
                console.log(response.data);
            });
            console.log("true");
            this.editar = true;
        }
    },
    methods:{
        crear(){
            console.log(this.asociado.correo)
            if (!this.editar) {
                this.axios.post("/api/asociados",this.asociado, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response => {
                console.log(response);
                Swal.fire({
                   icon: 'success',
                   title: 'Asociado Creado!',
                   showConfirmButton: false,
                   timer: 1500
                });    
                }).catch(error => {
                console.log(error);
                Swal.fire({
                   title: error,
                   icon: 'error',
                   showConfirmButton: false,
                   timer: 1500
                });
                });
            }else{
                
                this.axios.patch(`/api/asociados/${this.$route.params.id}`,this.asociado, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response => {
                Swal.fire({
                   icon: 'success',
                   title: 'Asociado Editado!',
                   showConfirmButton: false,
                   timer: 1500
                });    
                }).catch(error => {
                Swal.fire({
                   title: error,
                   icon: 'error',
                   showConfirmButton: false,
                   timer: 1500
                });
                });
            }

       }
    }
};
</script> 
