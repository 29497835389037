<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">Pertenencias</h3>
                    <form @submit.prevent="addPertenencias">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Artículo</label>
                                    <input type="text" class="form-control" v-model="pertenencia.pertenencia" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Detalle</label>
                                    <textarea type="text" class="form-control" v-model="pertenencia.detalle"
                                        rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Foto</label>
                                    <input type="file" class="form-control" @change="obtenerDocumento" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btnAgregar">
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pertenencias: [],
            joven: {},
            pertenencia: { joven: "" },
            documento: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/pertenencias/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.pertenencias = response.data.pertenencias;
                this.joven = response.data.joven;
            });
    },
    methods: {
        obtenerDocumento(event) {
            let file = event.target.files[0];
            this.documento.archivo = file;
        },
        addPertenencias() {
            let formData = new FormData();
            formData.append("foto", this.documento.archivo);
            formData.append("pertenencia", this.pertenencia.pertenencia);
            formData.append("detalle", this.pertenencia.detalle);
            formData.append("joven", this.joven.id);
            this.axios
                .post("/api/pertenencias", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                })
                .then((response) => console.log("estou" + this.images))
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Pertenencia creada!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>
