<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/empresas" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4" v-if="$route.params.id">Editar Empresa</h3>
                    <h3 class="title text-center mt-4" v-else>Crear Empresa</h3>
                    <form @submit.prevent="addEmpresas">
                        <div class="form-group">
                            <div class="row">
                                <div class="form-group1 col">
                                    <label>Nombre</label>
                                    <input type="text" class="form-control" v-model="empresa.name" required />
                                </div>
                                <div class="form-group1 col">
                                    <label>Subdominio</label>
                                    <input type="text" class="form-control" v-model="empresa.subdominio" required />
                                </div>
                                <div class="form-group1 col">
                                    <label>Base de Datos</label>
                                    <input type="text" class="form-control" v-model="empresa.db_name" required />
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <label>Descripcion</label>
                                <textarea type="text" class="form-control" v-model="empresa.descripcion"></textarea>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="form-group1 col">
                                    <label>Color Primario</label>
                                    <input type="color" class="form-control" v-model="empresa.color_primario"
                                        required />
                                </div>
                                <div class="form-group1 col">
                                    <label>Color Secundario</label>
                                    <input type="color" class="form-control" v-model="empresa.color_secundario"
                                        required />
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="form-group1 col">
                                    <label>Logo</label>
                                    <input type="file" class="form-control" accept="image/png, image/jpeg, image/jpg"
                                        @change="obtenerImagen" :required="isRequired" />

                                </div>
                                <div class="form-group border">
                                    <figure style="height: 200px; width: 200px; margin:auto;">
                                        <img v-if="imagenes || empresa.logo" class="img-thumbnail"
                                            :src="imagenes || `${filebaseurl}/logos_empresas/` + empresa.logo"
                                            width="200" height="200" alt="Foto" />
                                    </figure>
                                </div>
                            </div>


                        </div>
                        <button type="submit" class="btnAgregar">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            filebaseurl: process.env.VUE_APP_FILES_BASE_URL,
            empresas: [],
            empresa: {},
            imagen: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
            imagenMini: "",
        };
    },
    created() {
        if (this.$route.params.id) {
            this.axios
                .get(`/api/empresa/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((res) => {
                    this.empresa = res.data;
                });
        } else {
            this.axios.get("/api/empresas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.empresas = response.data;
            });
        }

    },
    methods: {
        obtenerImagen(event) {
            let file = event.target.files[0];
            this.imagen.archivo = file;
            this.cargarImagen(file);
        },
        cargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenMini = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        addEmpresas() {
            let formData = new FormData();

            formData.append('name', this.empresa.name);
            formData.append('descripcion', this.empresa.descripcion);
            formData.append('subdominio', this.empresa.subdominio);
            formData.append('db_name', this.empresa.db_name);
            formData.append('color_primario', this.empresa.color_primario);
            formData.append('color_secundario', this.empresa.color_secundario);
            formData.append('logo', this.imagen.archivo);

            if (this.$route.params.id) {

                formData.append('_method', 'PATCH')

                this.axios
                    .post(
                        `/api/empresa/${this.$route.params.id}`,
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                    )
                    .then((response) => {

                        Swal.fire({
                            icon: "success",
                            title: "Empresa actualizada!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();

                    });
            } else {

                this.axios
                    .post("/api/empresa", formData, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    })
                    .then((response) => {

                        Swal.fire({
                            icon: "success",
                            title: "Empresa creada!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.$router.push({ name: 'empresas' });

                    });
            }

        },
    },
    computed: {
        imagenes() {
            return this.imagenMini;
        },
        isRequired() {
            return this.$route.params.id === undefined;
        }
    },
};
</script>
