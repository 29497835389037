var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-1 col-md-12"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-sm-3 col-xs-10",staticStyle:{"padding-right":"0px"}},[_c('a',{staticClass:"btn btn-info pull-right",attrs:{"id":"volver"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"})]),_c('h3',{staticClass:"title"},[_vm._v("Pertenencias")])]),_c('div',{staticClass:"col-sm-9 col-xs-12 text-right"},[_c('div',{staticClass:"btn_group"},[_c('router-link',{staticClass:"btn btn-info pull-left",staticStyle:{"margin-top":"-7px","margin-left":"2px"},attrs:{"to":{
                                    name: 'addPertenencias',
                                    params: { id: _vm.joven.id },
                                },"id":"agregar"}},[_vm._v("Agregar "),_c('i',{staticClass:"fas fa-plus"})])],1)])])]),_c('div',{staticClass:"panel-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-condensed"},[_vm._m(0),_c('tbody',_vm._l((_vm.datos),function(pertenencia){return _c('tr',{key:pertenencia.id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(pertenencia.pertenencia)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(pertenencia.detalle)+" ")]),_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"pdf",attrs:{"target":"_blank","href":`${_vm.filebaseurl}/${pertenencia.foto}`,"alt":""}},[_vm._v("Foto")])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
                                            name: 'editPertenencias',
                                            params: { id: pertenencia.id },
                                        }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deletePertenencia(
                                                pertenencia.id
                                            )}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)])])}),0)]),_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getPreviousPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}})]),_vm._l((_vm.totalPaginas()),function(pagina){return _c('li',{key:_vm.isActive(pagina),staticClass:"page-item",on:{"click":function($event){return _vm.getDataPagina(pagina)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(pagina))])])}),_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getNextPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(">")])])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Artículo")]),_c('th',{staticClass:"text-center"},[_vm._v("Detalle")]),_c('th',{staticClass:"text-center"},[_vm._v("Archivo")]),_c('th',{staticClass:"text-center"})])])
}]

export { render, staticRenderFns }