<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px">
                            <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                                    class="fas fa-arrow-alt-circle-left"></i></a>
                            <h3 class="title">Datos de llamadas</h3>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link :to="{
                                    name: 'addLlamadas',
                                    params: { id: joven.id },
                                }" id="agregar" class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px">Agregar <i
                                        class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table class="
                            table table-bordered table-striped table-condensed
                        ">
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Número de teléfono</th>
                                <th class="text-center">Tiempo de llamada</th>
                                <th class="text-center">Autorizado por</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="llamada in datos" :key="llamada.id">
                                <td class="text-center">{{ llamada.nombre }}</td>
                                <td class="text-center">{{ llamada.numero_telefono }}</td>
                                <td class="text-center">{{ llamada.tiempo }}</td>
                                <div class="justify-content-center align-items-center" v-if="
                                    llamada.autorizado_por ===
                                    'Administrador'
                                ">
                                    <td class="text-center">{{ llamada.autorizado_por }}</td>
                                </div>
                                <div v-else class="justify-content-center align-items-center">
                                    <td class="text-center">
                                        Oficina de {{ llamada.autorizado_por }}
                                    </td>
                                </div>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <router-link :to="{
                                            name: 'editLlamadas',
                                            params: { id: llamada.id },
                                        }" class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                                        <button class="btn btn-danger" @click="deleteLlamada(llamada.id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#"></a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
    components: {
        VueHtml2pdf,
    },
    inject: ["reload"],
    data() {
        return {
            llamadas: [],
            joven: [],
            oficinas: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
        };
    },
    created() {
        this.axios
            .get(`/api/llamadas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.llamadas = response.data.llamadas;
                this.joven = response.data.joven;
                this.oficinas = response.data.oficinas;
                this.getDataPagina(1);
            });
    },
    methods: {
        deleteLlamada(id) {
            this.axios.delete(`/api/llamadas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
            ).then((response) => {
                let i = this.llamadas.map((data) => data.id).indexOf(id);
                this.llamadas.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminado!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.llamadas.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.llamadas.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
        cargarOficina(id) {
            var nombres = "";
            for (var key in this.oficinas) {
                for (var i in this.oficinas[key]) {
                    if (this.oficinas[key].id == id) {
                        nombres = this.oficinas[key].nombre;
                    }
                }
            }
            return nombres;
        },
    },
};
</script>
