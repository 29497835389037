<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <router-link to="/proveedores" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></router-link>
          <h3 class="title text-center mt-4">Proveedor</h3>
          <form @submit.prevent="addProveedor">
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="proveedor.nombre" />
            </div>
            <div class="form-group">
              <label>Razón Social</label>
              <input type="text" class="form-control" v-model="proveedor.razon_social" />
            </div>

            <div class="form-group">
              <label>Plazo</label>
              <input type="text" class="form-control" v-model="proveedor.plazo" />
            </div>
            <div class="form-group">
              <label>Teléfono</label>
              <input type="text" class="form-control" v-model="proveedor.contacto_Telefonico" />
            </div>
            <div class="form-group">
              <label>Correo</label>
              <input id="email" type="email" class="form-control" v-model="proveedor.contacto_Correo" />
            </div>
            <div class="form-group">
              <label>Cuenta</label>
              <input type="text" class="form-control" v-model="proveedor.formas_pago_Cuenta" />
            </div>
            <button type="submit" class="btnAgregar">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      proveedores: [],
      proveedor: {}
    };
  },
  created() {
    if (this.$route.params.id) {
      // UPDATE ROUTE
      this.axios
        .get(`/api/proveedores/${this.$route.params.id}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((res) => {
          this.proveedor = res.data;
        });

    } else {
      // ADD ROUTE
      this.axios.get("/api/proveedores", {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      })
        .then((response) => {
          this.proveedores = response.data;
        });
    }

  },
  methods: {
    addProveedor() {
      if (this.$route.params.id) {
        // UPDATE
        this.axios
          .patch(
            `/api/proveedores/${this.$route.params.id}`,
            this.proveedor, {
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          }
          )
          .then((response) => {
            var resultado = response.data.resultado['resultado'];
            var x = parseInt(resultado[0]) === parseInt(1);
            if (x) {
              Swal.fire({
                title: 'Ingrese todos los datos',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500
              })

            }
            else {
              Swal.fire({
                icon: 'success',
                title: 'Proveedor Actualizado!',
                showConfirmButton: false,
                timer: 1500
              })
              this.reload();
            }
          });
      } else {
        // ADD
        this.axios.post("/api/proveedores", this.proveedor, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        }).then((response) => {
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0]) === parseInt(1);
          if (x) {
            Swal.fire({
              title: 'Ingrese todos los datos',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500
            })

          }
          else {
            Swal.fire({
              icon: 'success',
              title: 'Proveedor Creado!',
              showConfirmButton: false,
              timer: 1500
            })
            this.reload();
          }
        });
      }
    },
  },
};
</script>