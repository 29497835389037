<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-4 col-xs-6">
                            <h4 class="title">Historial de recibos de pago</h4>
                        </div>
                        <div class="col-sm-8 col-xs-12 text-right" style="margin-top: 0.5%;">
                            <div class="btn_group">
                                <router-link to="/addRecibosColaboradores" id="agregar" class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px">Generar recibo de pago
                                    <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table id="colaboradores" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th class="text-center">Colaborador</th>
                                <th class="text-center">Periodo</th>
                                <th class="text-center">Horas extras</th>
                                <th class="text-center">Feriados</th>
                                <th class="text-center">Vacaciones</th>
                                <th class="text-center">Deducciones</th>
                                <th class="text-center">Salario bruto</th>
                                <th class="text-center">Total a pagar</th>
                                <th class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="recibo in datos" :key="recibo.id">
                                <td class="text-center">{{ recibo.nombre }}</td>
                                <td class="text-center">{{ recibo.periodo }}</td>
                                <td class="text-center">{{ recibo.horasextra }}</td>
                                <td class="text-center">{{ recibo.feriados }}</td>
                                <td class="text-center">{{ recibo.vacaciones }}</td>
                                <td class="text-center">{{ recibo.total_deducciones }}</td>
                                <td class="text-center">{{ recibo.total_bruto }}</td>
                                <td class="text-center">{{ recibo.total_pagar }}</td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <button class="btn btn-danger" @click="
                                            deleteRecibo(
                                                recibo.id
                                            )
                                            ">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button class="btn btn-success" @click="
                                            confirmarDescarga(
                                                recibo.id
                                            )
                                            ">
                                            <i class="fas fa-file-download"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#">
                                    < </a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            recibos: [],
            pageSize: 5,
            datos: [],
            datosRecibo: {},
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios.get("/api/recibocolaborador", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.recibos = response.data;
            this.listLength = this.recibos.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteRecibo(id) {
            Swal.fire({
                title: "¿Desea continuar?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Delete",
                icon: "error",
            }).then((res) => {
                if (res.value) {
                    this.axios
                        .delete(`/api/recibocolaborador/${id}`, {
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                            }
                        })
                        .then((response) => {
                            let i = this.recibos
                                .map((data) => data.id)
                                .indexOf(id);
                            this.recibos.splice(i, 1);
                            this.reload();
                        });
                }
            });
        },
        confirmarDescarga(id){
            Swal.fire({
                title: "¿Desea continuar con la descarga?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                icon: "warning",
            }).then((res) => {
                if (res.isConfirmed) {
                    this.descargarRecibo(id);
                }
            });
        },
        async descargarRecibo(id) {
            try {
                const response = await this.axios.get(`/api/recibo-pago/${id}/pdf`, {
                    responseType: 'blob',  // Para manejar la respuesta como un archivo Blob
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.target = '_blank';
                fileLink.click();
            } catch (error) {
                console.error('Error al generar el recibo:', error);
            }
        },
        

        totalPaginas() {
            return Math.ceil(this.recibos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.recibos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
