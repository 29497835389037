
<template>    
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto"> 
                <div class="card-body">
                    
                    <router-link to="/recibos" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Crear recibos</h3>
                    <form @submit.prevent="crear"  v-if="listLength != 0">
                        
                        <div class="form-group"> 
                            <label>Asociado</label> 
                            <div class="autocomplete">
                                <div class="autocomplete-child">
                                    <input type="text" class="form-control" @input="autocompletado();"  id="inputNombreAsociado" autocomplete="false" required>
                                    <input type="text" v-model="recibo.id_asociado" hidden>

                                </div>
                                <div class="autocomplete-child-content" v-if="asociadosFiltrados">
                                    <div class="asociado-container" @click="llenarInputAsociado(asociado);" v-for="asociado in asociadosFiltradoslista" :key="asociado.id">
                                        {{asociado.nombre}} {{asociado.apellido}}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="form-group">
                            <label>Título del recibo</label>
                            <input type="text" class="form-control" v-model="recibo.nombre" required>
                        </div>
                        <div class="form-group"> 
                            <label>Monto</label> 
                            <input type="number" class="form-control" v-model="recibo.monto" required>
                        </div>
                        <div class="form-group"> 
                            <label>Mes y año del cobro</label> 
                            <input type="month" name="fecha" id="fecha" class="form-control" v-model="recibo.mes_cobro" required>
                        </div>


                        <button  type="submit" class="btnAgregar">Agregar</button>
                    </form>

                    <NoExist v-if="listLength == 0"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
    data() {
        return {
            asociados: [],
            recibo:{},
            editar:false,
            asociadosFiltrados:false,
            asociadosFiltradoslista:[],
            listLength:0
        };
    },
    created() {
        let currentRoute = this.$route.path;
        const arrayRoute = currentRoute.split("/");
        this.axios.get(`/api/asociados`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            console.log(response.data)
            this.asociados = response.data;
            this.listLength = this.asociados.length;

        });
        if(arrayRoute[1] == 'editRecibos'){
            this.axios.get(`/api/recibos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.recibo = response.data[0];
                console.log(this.recibo.id_asociado);
                this.llenarInputAsociado(this.recibo,true);
            });
            this.editar = true;
        }
    },
    methods:{
        autocompletado(){
            let nombreAsociado = document.getElementById('inputNombreAsociado').value;
            nombreAsociado = nombreAsociado.replace(/ /g, "");
            this.asociadosFiltradoslista = [];
            let nombreAsociadoMinuscula = nombreAsociado.toLowerCase();
            if (nombreAsociado.length == 0) {
                this.asociadosFiltrados = false;
            }
            this.asociados.filter(asociado => {
                let nombreApellido = `${asociado.nombre}${asociado.apellido}`;
                if (nombreApellido.toLowerCase().includes(nombreAsociadoMinuscula) ) {
                    this.asociadosFiltradoslista.push(asociado);   
                }
            });
            this.asociadosFiltradoslista.length > 0 ? this.asociadosFiltrados = true : this.asociadosFiltrados = false;
        },

        llenarInputAsociado(asociado,editar){
            this.asociadosFiltrados = false;
            editar ? document.getElementById('inputNombreAsociado').value = `${asociado.nombreAsociado} ${asociado.apellido}`:document.getElementById('inputNombreAsociado').value = `${asociado.nombre} ${asociado.apellido}`;
            
            this.recibo.id_asociado = asociado.id;
            this.recibo.monto = asociado.monto;
        },
        crear(){
            if (!this.editar) {
                this.axios.post("/api/recibos",this.recibo, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response => {
                Swal.fire({
                   icon: 'success',
                   title: 'Recibo Creado!',
                   showConfirmButton: false,
                   timer: 1500
                });    
                }).catch(error => {
                console.log(error);
                Swal.fire({
                   title: error,
                   icon: 'error',
                   showConfirmButton: false,
                   timer: 1500
                });
                });
            }else{
                this.axios.patch(`/api/recibos/${this.$route.params.id}`,this.recibo, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response => {
                Swal.fire({
                   icon: 'success',
                   title: 'Recibo Editado!',
                   showConfirmButton: false,
                   timer: 1500
                });    
                }).catch(error => {
                Swal.fire({
                   title: error,
                   icon: 'error',
                   showConfirmButton: false,
                   timer: 1500
                });
                });
            }

       }
    }
};
</script> 
