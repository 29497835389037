<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white justify-content-between">
      <router-link class="navbar-brand" :to="{ name: 'home' }">
        <img class="logo d-inline-block align-top" :src="`${filebaseurl}/logos_empresas/${logoPath}`" alt="Logo" />
      </router-link>
      <ul class="navbar-nav justify-content-flex-end">
        <!-- <li class="nav-item">
          <button type="button" data-toggle="modal" data-target="#myModal">
            Crear Evento
          </button>
        </li> -->
        <li class="nav-item">
          <button @click="logout">Logout</button>
        </li>
      </ul>
    </nav>

    <!-- <div class="container-fluid">
      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myModalLabel">Nuevo Evento</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="nuevoEventoForm">
                @csrf
                <div class="mb-3">
                  <label for="tipo" class="form-label">Tipo de Evento</label>
                  <select class="form-control" id="tipo" name="tipo">
                    <option value=""></option>
                    @foreach ($tipos as $tipo)
                    <option value="{{ $tipo['id'] }}">{{ $tipo['nombre'] }}</option>
                    @endforeach
                  </select>
                </div>
                <div class="mb-3">
                  <label for="puntaje" class="form-label">Puntos</label>
                  <input type="text" class="form-control" id="puntaje" name="puntaje">
                </div>
                <div class="mb-3">
                  <label for="joven" class="form-label">Joven</label>
                  <select class="form-control" id="joven" name="joven">
                    <option value=""></option>
                    @foreach ($jovenes as $joven)
                    <option value="{{ $joven['id'] }}">{{ $joven['nombre'] }}</option>
                    @endforeach
                  </select>
                </div>
                <div class="mb-3">
                  <label for="descripcion" class="form-label">Descripción</label>
                  <textarea class="form-control" id="descripcion" name="descripcion"></textarea>
                  <div class="hidden">
                    <button type="button" id="recording" style="background: transparent; border: none;">
                      <i class="fa fa-microphone" style="color: #861473;"></i>
                    </button>
                    <button type="button" id="stop" style="background: transparent; border: none;">
                      <i class="fa fa-stop" style="color: #861473;"></i>
                    </button>
                  </div>
                </div>
                <button type="button" id="save" class="btnAgregar">Agregar</button>
              </form>

            </div>

          </div>
        </div>
      </div> -->
    <div class=parent>
      <div class="div1">
        <sidebar />
      </div>
      <div class="div2">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import sidebar from '@/components/Navbar/Sidebar.vue'
import { mapState } from 'vuex';


export default {

  data() {
    return {
      empresas: [],
      selectedEmpresa: null,
      showOptions: false,
      isRouterAlive: true,
      filebaseurl: process.env.VUE_APP_FILES_BASE_URL,
    }
  },
  name: 'home',

  computed: {
    ...mapState(['logoPath']),
    user() {
      return this.$store.getters.getUser;
    },

  },
  created() {
    this.getNombreEmpresas();
  },
  methods: {

    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'Login' }); // Redirigir al usuario a la página de login
    },

    getNombreEmpresas() {
      this.axios.get('/api/nombre-empresas', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      })
        .then((response) => {
          this.empresas = response.data;
        }).catch(error => {
          console.log(error);
        });
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    selectEmpresa(empresa) {
      this.selectedEmpresa = empresa;
      this.showOptions = false;
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },


  components: {
    sidebar
  }
}
</script>


<style scoped>
.parent {
  display: flex;
  height: 100vh;
  gap: 5px;
}

.div2 {
  width: 100%;

}

button {
  font-family: PlusJakartaSans, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Cantarell, Helvetica Neue, Ubuntu, sans-serif;
  font-size: 1rem;
  align-items: center;
  height: 48px;
  border-radius: 0.4rem;
  font-weight: 600;
  padding: 0 1.2rem;
  color: #ddd;
  border: none;
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(143, 142, 142, 0.15) !important;
  background: var(--color-primario);
  width: auto;
}

button:hover {
  background: var(--color-secundario);
}

/* DropDown Empresas */

.select {
  position: relative;
  width: 200px;
  cursor: pointer;
}
.selected {
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--color-primario);
}
.arrow {
  width: 12px;
  height: 12px;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid var(--color-primario);
  border-top: none;
  background-color: var(--color-primario);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}
.option {
  padding: 10px;
  cursor: pointer;
  background-color: var(--color-primario);
  transition: background-color 0.3s; 
}
.option:hover {
  filter: brightness(75%); 
}


@media (max-width: 1030px) {
  .div2{
    max-width: 90%;
  }

}
</style>