<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">
                        Actualizar datos de llamada
                    </h3>
                    <form @submit.prevent="updateLlamada">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" v-model="llamada.nombre" />
                        </div>
                        <div class="form-group">
                            <label>Número de teléfono</label>
                            <input type="number" class="form-control" v-model="llamada.numero_telefono" />
                        </div>
                        <div class="form-group">
                            <label>Tiempo</label>
                            <input type="text" class="form-control" v-model="llamada.tiempo" />
                        </div>
                        <div class="form-group">
                            <label>Autorizado por</label>
                            <select class="form-control" v-model="llamada.autorizado_por">
                                <option value="Administrador">
                                    Administrador
                                </option>
                                <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.nombre"
                                    v-text="oficina.nombre">
                                </option>
                            </select>
                        </div>
                        <button type="submit" class="btnAgregar">Editar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            llamada: {},
            oficinas: [],
            oficina: {},
        };
    },
    created() {
        this.axios
            .get(`/api/llamadas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.llamada = response.data.llamada;
                this.oficinas = response.data.oficinas;
            });
    },
    methods: {
        updateLlamada(id) {
            this.axios
                .patch(`/api/llamadas/${this.$route.params.id}`, this.llamada, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((res) => {
                    this.$router.go(0);
                });
            Swal.fire({
                icon: "success",
                title: "Datos de llamada actualizados!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>
