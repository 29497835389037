var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-1 col-md-12"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-sm-3 col-xs-10"},[_c('a',{staticClass:"btn btn-info pull-right",attrs:{"id":"volver"},on:{"click":function($event){return _vm.$router.push({name :'menuJovenes', params: {id: _vm.joven.id}})}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"})]),_c('h3',{staticClass:"title"},[_vm._v("Expediente Educativo")])]),_c('div',{staticClass:"col-sm-9 col-xs-12 text-right"},[_c('div',{staticClass:"btn_group"},[_c('router-link',{staticClass:"btn btn-info pull-left",staticStyle:{"margin-top":"-7px","margin-left":"2px"},attrs:{"to":{
                                    name: 'addExpedientes',
                                    params: { id: _vm.joven.id },
                                },"id":"agregar"}},[_vm._v("Agregar "),_c('i',{staticClass:"fas fa-plus"})])],1)])])]),_c('div',{staticClass:"panel-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-condensed"},[_vm._m(0),_c('tbody',_vm._l((_vm.datos),function(expediente){return _c('tr',{key:expediente.id},[_c('td',[_vm._v(_vm._s(expediente.fecha))]),_c('td',[_vm._v(_vm._s(expediente.institutoActual))]),_c('td',[_vm._v(_vm._s(expediente.institutoAnterior))]),_c('td',[_vm._v(_vm._s(expediente.primer_periodo))]),_c('td',[_vm._v(_vm._s(expediente.segundo_periodo))]),_c('td',[_vm._v(_vm._s(expediente.tercer_periodo))]),_c('td',[(expediente.boletaMatricula)?_c('a',{staticClass:"pdf",attrs:{"target":"_blank","href":`${_vm.filebaseurl}${expediente.boletaMatricula}`,"alt":""}},[_vm._v("Boleta de Matrícula")]):_vm._e()]),_c('td',[(expediente.notas)?_c('a',{staticClass:"pdf",attrs:{"target":"_blank","href":`${_vm.filebaseurl}${expediente.notas}`}},[_vm._v(" Notas")]):_vm._e()]),_c('td',[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                            name: 'verExpediente',
                                            params: { id: expediente.id },
                                        }}},[_c('i',{staticClass:"fa-solid fa-eye"})]),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
                                            name: 'editExpedientes',
                                            params: { id: expediente.id },
                                        }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteExpediente(expediente.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)])])}),0)]),_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getPreviousPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}})]),_vm._l((_vm.totalPaginas()),function(pagina){return _c('li',{key:_vm.isActive(pagina),staticClass:"page-item",on:{"click":function($event){return _vm.getDataPagina(pagina)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(pagina))])])}),_c('li',{staticClass:"page-item",on:{"click":function($event){return _vm.getNextPage()}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}})])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Instituto Actual")]),_c('th',[_vm._v("Instituto Anterior")]),_c('th',[_vm._v("Nota I Periodo")]),_c('th',[_vm._v("Nota II Periodo")]),_c('th',[_vm._v("Nota III Periodo")]),_c('th',[_vm._v("Boleta Matrícula")]),_c('th',[_vm._v("Notas")]),_c('th')])])
}]

export { render, staticRenderFns }