<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/oficinas" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Crear Oficina</h3>
                    <form @submit.prevent="addOficinas">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" v-model="oficina.nombre" />
                        </div>
                        <button type="submit" class="btnAgregar">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            oficinas: [],
            oficina: {},
        };
    },
    created() {
        if (this.$route.params.id) {
            this.axios
                .get(`/api/oficinas/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((res) => {
                    this.oficina = res.data;
                });
        } else {
            this.axios.get("/api/oficinas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.oficinas = response.data;
            });
        }

    },
    methods: {
        addOficinas() {
            if (this.$route.params.id) {
                this.axios
                    .patch(
                        `/api/oficinas/${this.$route.params.id}`,
                        this.oficina, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                    )
                    .then((response) => {
                        var resultado = response.data.resultado["resultado"];
                        var x = parseInt(resultado[0]) === parseInt(1);
                        if (x) {
                            Swal.fire({
                                title: "Ingrese todos los datos",
                                icon: "error",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Oficina actualizada!",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.reload();
                        }
                    });
            } else {
                this.axios
                    .post("/api/oficinas", this.oficina, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    })
                    .then((response) => {
                        var resultado = response.data.resultado["resultado"];
                        var x = parseInt(resultado[0]) === parseInt(1);
                        if (x) {
                            Swal.fire({
                                title: "Ingrese todos los datos",
                                icon: "error",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                title: "Oficina creada!",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.reload();
                        }
                    });
            }

        },
    },
};
</script>
