
<template>    
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto"> 
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Recetas</h3>
      <form @submit.prevent="updateReceta">
      <div class="form-group">
        <label>Medicamento</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.medicamento"
          />
      </div>
      <div class="form-group">
        <label>Dosis</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.dosis"
        />
      </div>
      <div class="form-group">
        <label>Frecuencia</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.frecuencia"
        />
      </div>
      <div class="form-group">
        <label>Estado</label>
        <select class="form-control" v-model="receta.estado">
          <option value="cronicas">Crónicas</option>
          <option value="agudo">Agudo</option>
          <option value="de ser necesario">De ser necesario</option>
          <option value="activa">Activa</option>
          <option value="inactiva">Inactiva</option>
        </select>
      </div>
      <button  type="submit" class="btnAgregar">Editar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      receta:{}, 
    };
  },
  created() {
      this.axios
      .get(`/api/recetas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.receta = response.data.receta;
    });
  },
   methods: {
    updateReceta() {
      this.axios
        .patch(
          `/api/recetas/${this.$route.params.id}`,
          this.receta , {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((res) => {
          this.$router.go(0);
        });
        Swal.fire({
        icon: 'success',
        title: 'Receta Actualizada!',
        showConfirmButton: false,
        timer: 1500
      })
    },
    
  },
};
</script> 