<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div
                            class="col"
                            style="padding-right: 0px; max-width: 5%"
                        >
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div class="col">
                            <h1 class="text-center" style="color: gray">
                                Vacaciones por colaborador
                            </h1>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 4%;">
                        <div
                            class="col"
                            v-if="listLength != 0"
                        >
                            <label id="reportes">Colaborador</label>
                            <select
                                id="joven"
                                style="
                                    width: 200px;
                                    height: 29px;
                                    border-radius: 5px;
                                "
                            >
                                <option
                                    id="datos"
                                    v-for="joven in jovenes"
                                    :key="joven.id"
                                    :value="joven.id"
                                    v-text="joven.nombre"
                                ></option>
                            </select>
                        </div>
                        <div class="col">
                            <label
                                >Fecha Inicio</label
                            >
                            <input
                                type="date"
                                id="fecha_inicio"
                                style="border-radius: 5px; width: 200px"
                            />
                        </div>
                        <div class="col">
                            <label>Fecha Final</label>
                            <input
                                type="date"
                                id="fecha_final"
                                style="border-radius: 5px; width: 200px"
                            />
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                @click="buscar()"
                                style="width: 150px"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                style="
                                    background-color: #fec107;
                                    border-color: #fec107;
                                    width: 150px;
                                "
                                class="btn btn-danger"
                                @click="generateReport()"
                            >
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Desde</th>
                                <th class="text-center">Hasta</th>
                                <th class="text-center">Comentarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td class="text-center">
                                    {{ reporteL.nombre }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.desde }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.hasta }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.comentarios }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Vacaciones"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="1000px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style="
                                color: black;
                                padding-left: 20%;
                                padding-top: 4%;
                            "
                            class="
                                align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2
                                class="text-center"
                                style="margin-left: 23%; margin-bottom: 4%"
                            >
                                Vacaciones
                            </h2>
                            <div class="panel-body table-responsive">
                                <NoExist v-if="listLength == 0" />
                                <table
                                    class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    "
                                    v-if="listLength != 0"
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Nombre</th>
                                            <th class="text-center">Desde</th>
                                            <th class="text-center">Hasta</th>
                                            <th class="text-center">
                                                Comentarios
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td class="text-center">
                                                {{ reporteL.nombre }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.desde }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.hasta }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.comentarios }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            jovenes: [],
            llamadas: [],
            datos: {},
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/vacaciones-colaborador`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            console.log(response.data);
            this.jovenes = response.data.jovenes;
            this.llamadas = response.data.llamadas;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            var joven = document.getElementById("joven").value;
            var fecha_inicio = document.getElementById("fecha_inicio").value;
            var fecha_final = document.getElementById("fecha_final").value;
            var aux = new Array();
            for (var key in this.llamadas) {
                if (this.llamadas[key].usuario == joven) {
                    if (
                        this.llamadas[key].created_at >= fecha_inicio &&
                        this.llamadas[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            desde: "",
                            hasta: "",
                            comentarios: "",
                        };
                        aux2.nombre = this.llamadas[key].nombre;
                        aux2.desde = this.llamadas[key].desde;
                        aux2.hasta = this.llamadas[key].hasta;
                        aux2.comentarios = this.llamadas[key].comentarios;
                        var fecha = new Date(this.llamadas[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.periodos) {
                if (this.periodos[key].joven == joven) {
                    if (
                        this.periodos[key].created_at >= fecha_inicio &&
                        this.periodos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Periodo";
                        var fecha = new Date(this.periodos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = "Registrado";
                        aux.push(aux2);
                    }
                }
            }

            this.datos = aux;
        },
    },
};
</script>
