<template>
    <div>
    
        <button @click="toggleSidebar" class="toggleBtn">
            <i class="icon fa fa-bars"></i>
        </button>
        <nav class="sidebar" id="sidebar" :style="sidebarDispose">
            <!-- Botones del Sidebar -->
            <ul>
                <!-- DropDown Eventos -->
                <li class="dropdown"
                    v-show="userRole === 'Administrador' || userRole === 'Nivel1' || userRole === 'SuperAdministrador'">
                    <a href="#">
                        <i class="icon fas fa-x fa-calendar-week"></i>
                        <span>Eventos</span>
                    </a>
                    <div class="dropdown-content">

                        <a class="dropdown-item" @click="$router.push({ path: '/eventos' })">
                            <i class="icon fa-solid fa-calendar-week"></i>
                            Eventos
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/tipos' })">
                            <i class="icon fa-solid fa-calendar-days"></i>
                            Tipos
                        </a>
                    </div>
                </li>
                <!-- DropDown Recursos Humanos n1 y n2 -->
                <li class="dropdown" v-show="userRole === 'Nivel1' || userRole === 'Nivel2'">
                    <!-- Boton -->
                    <a href="#">
                        <i class="icon fa-solid fa-people-group"></i>
                        <span>Recursos Humanos</span>
                    </a>
                    <div class="dropdown-content">
                        <a class="s-sidebar__nav-link" @click="$router.push({ path: '/vacacionesempleados', })">
                            <i class="icon fa-solid fa-person-walking-luggage"></i>
                            Control de Vacaciones
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/permisosempleados', })">
                            <i class="icon fa-solid fa-user-check"></i>
                            Permisos
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/horasextrasempleados', })">
                            <i class="icon fa-solid fa-user-clock"></i>
                            Horas Extras
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/feriadosempleados', })">
                            <i class="icon fa-solid fa-calendar-days"></i>
                            Días Feriados
                        </a>
                    </div>
                </li>
                <!-- DropDown Recursos Humanos admin-->
                <li class="dropdown" v-show="userRole === 'Administrador' || userRole === 'SuperAdministrador'">
                    <!-- Boton -->
                    <a class="s-sidebar__nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="icon fa-solid fa-people-group"></i>
                        <span>Recursos Humanos</span>
                    </a>
                    <div class="dropdown-content">
                        <a class="dropdown-item" @click="$router.push({ path: '/vacacionesadm', })">
                            <i class="icon fa-solid fa-person-walking-luggage"></i>
                            Control de Vacaciones
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/permisosadm', })">
                            <i class="icon fa-solid fa-user-check"></i>
                            Control de Permisos
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/incapacidades', })">
                            <i class="icon fa-solid fa-head-side-mask"></i>
                            Incapacidades
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/horasextras', })">
                            <i class="icon fa-solid fa-user-clock"></i>
                            Horas Extras
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/feriados', })">
                            <i class="icon fa-solid fa-calendar-days"></i>
                            Días Feriados
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/recibocolaborador', })">
                            <i class="icon fa-solid fa-money-check-dollar"></i>
                            Generar Recibos
                        </a>
                        <a class="dropdown-item" @click="$router.push({ path: '/aguinaldos', })">
                            <i class="icon fa-solid fa-money-bill-trend-up"></i>
                            Aguinaldos
                        </a>
                    </div>
                </li>

                <li v-show="userRole === 'Administrador' || userRole === 'SuperAdministrador'">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/presupuestos' })">
                        <i class="icon fas fa-hand-holding-usd"></i><span>Presupuestos</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/egresos' })">
                        <i class="icon fas fa-funnel-dollar"></i>
                        <span>Egresos</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel1' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/jovenes' })">
                        <i class="icon fas fa-child"></i>
                        <span>Jóvenes</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/proveedores' })">
                        <i class="icon fas fa-people-carry"></i>
                        <span>Proveedores</span>
                    </a>
                </li>
                <li v-show="userRole === 'Administrador' || userRole === 'SuperAdministrador'">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/usuarios' })">
                        <i class="icon fas fa-user"></i>
                        <span>Usuarios</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="
                        $router.push({ path: '/colaboradores' })
                        ">
                        <i class="icon fas fa-users"></i>
                        <span>Colaboradores</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel1' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/menuReportes' })">
                        <i class="icon fas fa-file-alt"></i>
                        <span>Reportes</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/asociados' })">
                        <i class="icon fa-solid fa-people-group"></i>
                        <span>Asociados</span>
                    </a>
                </li>

                <li v-show="userRole === 'Administrador' ||
                    userRole === 'Nivel2' || userRole === 'SuperAdministrador'
                    ">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/recibos' })">
                        <i class="icon fa-solid fa-receipt"></i>
                        <span>Recibos</span>
                    </a>
                </li>
                <!-- DropDown Configuraciones -->
                <li class="dropdown" v-show="userRole === 'Administrador' || userRole === 'SuperAdministrador'">
                    <!-- Boton -->
                    <a class="s-sidebar__nav-link" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="icon fa-solid fa-gear"></i>
                        <span>Configuraciones</span>
                    </a>

                    <div class="dropdown-content">
                        <a class="dropdown-item" @click="$router.push({ path: '/oficinas', })">
                            <i class="icon fa-solid fa-building"></i>
                            Oficinas Locales
                        </a>
                    </div>
                </li>
                <li v-show="userRole === 'SuperAdministrador'">
                    <a class="s-sidebar__nav-link" @click="$router.push({ path: '/empresas' })">
                        <i class="icon fa-solid fa-city"></i>
                        <span>Empresas</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>

</template>

<script>

export default {
    data() {
        return {
            isSidebarVisible: true,
        };
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        sidebarDispose() {
            return {
                display: this.isSidebarVisible ? 'block' : 'none',
            };
        },
        userRole() {
            return this.$store.getters.getUserRole;
        }
    },
    methods: {
        toggleSidebar() {
            this.isSidebarVisible = !this.isSidebarVisible;
        }
    }

};
</script>
<style scoped>
/* Estilos del Sidebar */
.sidebar {
    background-color: var(--color-primario);
    padding: 15px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in;
    height: auto;
    width: 15em;
    transition: width 0.3s, display 0.3s;
    border-radius: 0% 3% 3% 0%;
}

.sidebar ul {
    /*  */
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    /* */
    margin: 10px 0;
}

.sidebar li span {
    display: inline;
    padding-left: 1%;
    font-size: large;

}

.sidebar li a {
    text-decoration: none;
    color: white;
}


/* Estilos para los Botones con mas opciones (Dropdown de eventos,Recursos humanos, etc...) */


.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--color-primario);
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3%;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Estilos para los botones del dropdown */
.dropdown-item {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: normal;
    padding-top: 6px;
    padding-bottom: 6px;
}

.dropdown-item:hover {
    color: var(--color-secundario);
}


/* Boton de menu Hamburguesa para resoluciones de tablet y movil  */


.toggleBtn {
    display: none;
    background-color: var(--color-primario);
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    z-index: 1;
}

.icon {
    font-size: 1.25rem;
}

/* Responsive styles */
@media (max-width: 1030px) {

    .icon {
        font-size: 1.50rem;
    }


    .sidebar {
        width: 4.5em;
    }

    .sidebar li span {
        display: none;
    }

    .sidebar ul {
        text-align: center;
    }
}

@media (max-width: 480px) {

    .sidebar {
        display: none;
    }

    .toggleBtn {
        display: block;
    }
}
</style>