<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row" style="margin-bottom: 3%">
                        <div class="col">
                            <h4 class="title">Mi historial de vacaciones</h4>
                        </div>
                        <div class="col text-right">
                            <div class="btn_group pull-left">
                                <router-link
                                    to="/addVacacionesEmpleados"
                                    id="agregar"
                                    class="btn btn-info"
                                    style="margin-top: -7px; margin-left: 2px"
                                    >Solicitar vacaciones
                                    <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col">
                            <div
                                class="
                                    alert alert-primary
                                    text-center
                                    border border-primary
                                "
                                role="alert"
                            >
                                Días totales:
                                <span style="font-weight: bold">
                                    {{ usuario.total_dias }}
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <div
                                class="
                                    alert alert-success
                                    text-center
                                    border border-start-0 border-success
                                "
                                role="alert"
                            >
                                Días disponibles:
                                <span style="font-weight: bold">
                                    {{ dias }}
                                </span>
                            </div>
                        </div>
                        <div class="col">
                            <div
                                class="
                                    alert alert-warning
                                    text-center
                                    border border-warning
                                "
                                role="alert"
                            >
                                Días tomados:
                                <span style="font-weight: bold">
                                    {{ usuario.dias_tomados }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Desde</th>
                                <th class="text-center">Hasta</th>
                                <th class="text-center">Cantidad de días</th>
                                <th class="text-center">Comentarios</th>
                                <th class="text-center">Estado</th>
                                <th class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="vacacion in searchVacacion"
                                :key="vacacion.id"
                            >
                                <td class="text-center">
                                    {{ vacacion.desde }}
                                </td>
                                <td class="text-center">
                                    {{ vacacion.hasta }}
                                </td>
                                <td class="text-center">
                                    {{ vacacion.cant_dias }}
                                </td>
                                <td class="text-center">
                                    {{ vacacion.comentarios }}
                                </td>
                                <td class="text-center">
                                    {{ vacacion.estado }}
                                </td>
                                <td class="text-center">
                                    <div
                                        v-show="vacacion.desde >= fechaActual()"
                                    >
                                        <div
                                            v-show="
                                                vacacion.estado === 'Pendiente'
                                            "
                                        >
                                            <div class="btn-group" role="group">
                                                <button
                                                    class="btn btn-danger"
                                                    @click="
                                                        deleteVacaciones(
                                                            vacacion.id
                                                        )
                                                    "
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav
                        aria-label="Page navigation example"
                        v-if="listLength != 0"
                    >
                        <ul class="pagination justify-content-center">
                            <li
                                class="page-item"
                                v-on:click="getPreviousPage()"
                            >
                                <a class="page-link" href="#"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                v-on:click="getDataPagina(pagina)"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
function buscarEnObjecto(objecto, palabra) {
    for (let key in objecto) {
        if (
            objecto.hasOwnProperty(key) &&
            objecto[key].toString().toLowerCase().includes(palabra)
        ) {
            return true;
        }
    }

    return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            vacaciones: [],
            dias: 0,
            usuario: {},
            pageSize: 10,
            datos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
            vacacion: {},
        };
    },
    computed: {
        searchVacacion: function () {
            return this.datos.filter((vacacion) => {
                return buscarEnObjecto(vacacion, this.busqueda.toLowerCase());
            });
        },
    },
    created() {
        this.axios.get("/api/vacacionesempleados", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.dias = response.data.dias_disponibles;
            this.vacaciones = response.data.vacaciones;
            this.usuario = response.data.user;
            this.listLength = this.vacaciones.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteVacaciones(id) {
            this.axios.patch(`/api/vacacionesempleados/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((res) => {
                this.$router.go(0);
            });
            Swal.fire({
                icon: "success",
                title: "Vacaciones canceladas!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.vacaciones.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.vacaciones.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
        fechaActual() {
            let date = new Date();
            let fechaActual =
                date.getFullYear() +
                "-" +
                String(date.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(date.getDate()).padStart(2, "0");
            return fechaActual;
        },
    },
};
</script>
