<template>
    <div class="container">
        <div class="row px-3">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.push({name: 'expedientes', params: {id: joven.id}})"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">
                        Expediente Educativo
                    </h3>
                    <form @submit.prevent="addExpedientes">
                        <div class="form-group">
                            <label>Fecha</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="expediente.fecha"
                            />
                        </div>
                        <div class="form-group">
                            <label>Instituto Actual</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="expediente.institutoActual"
                            />
                        </div>
                        <div class="form-group">
                            <label>Instituto Anterior</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="expediente.institutoAnterior"
                            />
                        </div>
                        <div class="form-group1">
                            <label>Nota I Periodo</label>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                class="form-control"
                                v-model="expediente.primer_periodo"
                            />
                        </div>
                        <div class="form-group1">
                            <label>Nota II Periodo</label>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                class="form-control"
                                v-model="expediente.segundo_periodo"
                            />
                        </div>
                        <div class="form-group1">
                            <label>Nota III Periodo</label>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                class="form-control"
                                v-model="expediente.tercer_periodo"
                            />
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Boleta de Matrícula</label>
                            <input
                                type="file"
                                class="form-control"
                                @change="obtenerDocumento"
                            />
                        </div>
                        <div class="form-group">
                            <label>Notas</label>
                            <input
                                type="file"
                                class="form-control"
                                @change="obtenerDocumento2"
                            />
                        </div>
                        <button type="submit" class="btnAgregar">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            expedientes: [],
            joven: {},
            fotosAcademicas: [],
            fotoAcademica: { joven: "" },
            expediente: { joven: "" },
            documento: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
            documento2: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/expedientes/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.expedientes = response.data.expedientes;
                this.joven = response.data.joven;
                this.fotosAcademicas = response.data.fotosAcademicas;
                
            });
    },
    methods: {
        obtenerDocumento(event) {
            let file = event.target.files[0];
            this.documento.archivo = file;
        },
        obtenerDocumento2(event) {
            let file2 = event.target.files[0];
            this.documento2.archivo2 = file2;
        },
        addExpedientes() {
            let formData = new FormData();
            if(this.$refs.file && this.$refs.file.files) {
                for (var i = 0; i < this.$refs.file.files.length; i++) {
                    let file = this.$refs.file.files[i];
                    formData.append("files[" + i + "]", file);
                }
                formData.append("boletaMatricula", this.documento.archivo);
                formData.append("notas", this.documento2.archivo2);
            }
            formData.append("institutoActual", this.expediente.institutoActual);
            formData.append("fecha", this.expediente.fecha);
            formData.append("primer_periodo", this.expediente.primer_periodo || '');
            formData.append("segundo_periodo", this.expediente.segundo_periodo || '');
            formData.append("tercer_periodo", this.expediente.tercer_periodo || '');
            formData.append(
                "institutoAnterior",
                this.expediente.institutoAnterior
            );
            formData.append("joven", this.joven.id);
            this.axios
                .post("/api/expedientes", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    },
                })
                .then((response) => console.log("estou" + this.images))
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Expediente Creado!",
                showConfirmButton: false,
                timer: 1500,
            })
            .then(() => {
                this.$router.push({name: 'expedientes', params: {id: this.joven.id}});
            });
        },
        addFotos() {
            let formData = new FormData();
            formData.append("foto", this.fotoAcademica.foto);
            formData.append("joven", this.joven.id);
            
            this.axios
                .post("/api/fotosAcademicas", formData, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => location.reload())
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Expediente Creado!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>