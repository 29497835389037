<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Títulos</h3>
          <form @submit.prevent="addTitulos">
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="titulo.nombre" />
            </div>
            <div class="form-group">
              <label>Fecha</label>
              <input type="date" class="form-control" v-model="titulo.fecha" />
            </div>
            <div class="form-group">
              <label>Institución</label>
              <input type="text" class="form-control" v-model="titulo.institucion" />
            </div>
            <button type="submit" class="btnAgregar">Agregar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titulos: [],
      joven: {},
      titulo: { 'joven': '' },
    };
  },
  created() {
    this.axios
      .get(`/api/titulos/${this.$route.params.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        this.titulos = response.data.titulos;
        this.joven = response.data.joven;
      });
  },
  methods: {
    addTitulos() {
      this.titulo.joven = this.joven.id;
      this.axios
        .post("/api/titulos", this.titulo, {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      Swal.fire({
        icon: 'success',
        title: 'Título Creado!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>