<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">
                        Generar recibo de pago
                    </h3>
                    <form @submit.prevent="addRecibos">
                        <div class="row">
                            <div class="form-group col">
                                <label>Colaborador</label>
                                <select class="form-control" v-model="incapacidad.colaborador">
                                    <option v-for="colaborador in colaboradores" :key="colaborador.id"
                                        :value="colaborador.id" v-text="colaborador.nombre"></option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Periodo</label>
                                <input type="text" class="form-control" v-model="recibo.periodo" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Cantidad de horas extra</label>
                                <input type="number" class="form-control" min="0" v-model="recibo.cant_horasextra" />
                            </div>
                            <div class="form-group col">
                                <label>Cantidad de días feriados</label>
                                <input type="number" min="0" class="form-control" v-model="recibo.cant_feriados" />
                            </div>
                            <div class="form-group col">
                                <label>Cantidad de días de vacaciones</label>
                                <input type="number" min="0" class="form-control" v-model="recibo.cant_vacaciones" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Otros</label>
                                <input type="text" class="form-control" v-model="recibo.otro_aumento" />
                            </div>
                            <div class="form-group col">
                                <label>Monto</label>
                                <input type="number" class="form-control" v-model="recibo.otros_aumentos" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="control form-group col">
                                <input type="radio" id="one" value="true" v-model="picked" />
                                <label for="one">Aplicar deducción
                                    <span style="font-weight: bold">CCSS 10.67%</span></label>
                            </div>
                            <!-- <div class="form-group col">
                                <label>Deducciones CCSS 10.50%</label>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Otras deducciones</label>
                                <input type="text" class="form-control" v-model="recibo.otra_deduccion" />
                            </div>
                            <div class="form-group col">
                                <label>Monto</label>
                                <input type="number" class="form-control" v-model="recibo.otras_deducciones" />
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btnAgregar">
                                Generar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    components: {
        NoExist,
    },
    data() {
        return {
            recibos: [],
            colaboradores: [],
            datosRecibo: {},
            incapacidad: {},
            recibo: {},
            componentKey: 0,
            picked: "false",
        };
    },
    created() {
        if (this.$route && this.$route.params.id) {
            this.axios
                .get(`/api/recibocolaborador/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    this.recibos = response.data.recibos;
                    // this.datosRecibo = response.data.datosRecibo;
                });
        }

        this.axios
            .get(`/api/colaboradores/`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.colaboradores = response.data.colaboradores;
            });
    },
    methods: {
        obtenerDatos() {
            this.axios
                .get(`/api/recibocolaborador/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    this.datosRecibo = response.data.datosRecibo;
                    //this.$refs.html2Pdf.generatePdf();
                });
        },
        generateReport() {
            //this.$refs.html2Pdf.generatePdf();
        },
        addRecibos() {
            let formData = new FormData();
            formData.append("ccss", this.picked);
            formData.append("colaborador", this.incapacidad.colaborador);
            formData.append("periodo", this.recibo.periodo);
            formData.append("cant_horasextra", this.recibo.cant_horasextra);
            formData.append("cant_feriados", this.recibo.cant_feriados);
            formData.append("cant_vacaciones", this.recibo.cant_vacaciones);
            formData.append("otro_aumento", this.recibo.otro_aumento);
            formData.append(
                "otros_aumentos",
                parseFloat(this.recibo.otros_aumentos)
            );
            formData.append("otra_deduccion", this.recibo.otra_deduccion);
            formData.append(
                "otras_deducciones",
                parseFloat(this.recibo.otras_deducciones)
            );
            this.axios
                .post("/api/recibocolaborador", formData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    this.obtenerDatos();
                });
        },
    },
};
</script>
